import { Select, Typography } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Subject } from "api/resources/global/types"
import { ProfileCompletionContext } from "components/ProfileCompletion/utils"
import React, { useContext, useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"

const formatSubjects = (subjects: Subject[]) =>
  subjects.map(item => ({ value: item.uuid, label: item.name }))

const Subjects = ({ label, name }: { label: string; name: string }) => {
  const {
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useFormContext()
  const { subjects } = useContext(ProfileCompletionContext)
  const subjectList = formatSubjects(subjects)

  useEffect(() => {
    api.profile.listInterests({ urlParams: {} }).then(response => {
      if (!response.isSuccessful) return

      const alreadySelectedSubject = getValues(name)
      if (alreadySelectedSubject) return

      const subjects = response.data.subjects
      if (subjects.length) {
        const subject = subjects[0].subject
        setValue(name, [{ value: subject.uuid, label: subject.name }])
      }
    })
  }, [])

  return (
    <div>
      <Typography className="mb-2" variant="strong">
        {label}
      </Typography>
      <div className="grid md:grid-cols-2">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <Select
              className="col-span-1"
              error={Boolean(errors[name])}
              helperText={errors[name]?.message as string}
              options={subjectList}
              placeholder="Ex. Physics"
              value={Array.isArray(value) ? value[0] : value}
              fullWidth
              required={false}
              onChange={option => {
                onChange([option])
              }}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      </div>
    </div>
  )
}

export default Subjects
