import { Button, Typography } from "@suraasa/placebo-ui-legacy"
import {
  AssessmentDetailsStep,
  JobApplicantStepStatus,
} from "api/resources/jobs/types"
import { format } from "date-fns"
import { Clock } from "iconoir-react"
import React from "react"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"

import ListItem from "../ListItem"

type Props = {
  Tag?: React.ReactElement
} & AssessmentDetailsStep

const AssessmentItem = ({
  id,
  Tag,
  status,
  dueDate,
  step: { title },
  dateCreated,
}: Props) => {
  const getAction = () => {
    if (
      dueDate &&
      new Date(dueDate) > new Date() &&
      status !== JobApplicantStepStatus.COMPLETED &&
      status !== JobApplicantStepStatus.SKIPPED
    ) {
      return (
        <Button
          component={Link}
          size="sm"
          to={routes.attemptAssessment.replace(
            ":jobApplicantStepId",
            id.toString()
          )}
        >
          Attempt Assessment
        </Button>
      )
    }
    return null
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="flex flex-wrap gap-1.5">
        <Typography color="onSurface.800" variant="strong">
          {title}
        </Typography>
        {Tag}
        {dateCreated && (
          <Typography
            className="md:ml-auto"
            color="onSurface.500"
            variant="smallBody"
          >
            Posted on {format(new Date(dateCreated), "do MMM yy")}
          </Typography>
        )}
      </div>
      <Typography color="onSurface.400" variant="smallBody">
        Assessment
      </Typography>
      {dueDate && (
        <ListItem icon={<Clock />}>
          <Typography
            color="onSurface.400"
            display="inline"
            variant="smallBody"
          >
            {new Date(dueDate) < new Date() ? "Was due" : "Due"} on{" "}
          </Typography>
          <Typography display="inline" variant="strongSmallBody">
            {format(new Date(dueDate), "dd MMMM yyyy',' hh:mm aa")}
          </Typography>
        </ListItem>
      )}
      <div className="mt-3 flex justify-end gap-1">{getAction()}</div>
    </div>
  )
}
export default AssessmentItem
