import { Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { Check } from "iconoir-react"
import { capitalize } from "lodash"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  checkBoxContainer: {
    background: theme.colors.primary[50],
    width: "20px",
    height: "20px",
    borderRadius: "4px",
    color: theme.colors.primary[500],
  },
  tileContainer: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
}))

function CheckTile({ label }: { label: string }) {
  const classes = useStyles()
  return (
    <div
      className={clsx("flex items-center gap-1 mb-2", classes.tileContainer)}
    >
      <div className={classes.checkBoxContainer}>
        <Check fontSize={13} />
      </div>
      <Typography variant="body">{capitalize(label)}</Typography>
    </div>
  )
}

export default CheckTile
