import { Button, Typography } from "@suraasa/placebo-ui-legacy"
import {
  InterviewDetailsStep,
  JobApplicantStepStatus,
} from "api/resources/jobs/types"
import { add, format } from "date-fns"
import { Clock } from "iconoir-react"
import React from "react"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"

import ListItem from "../ListItem"

type Props = {
  Tag?: React.ReactElement
} & InterviewDetailsStep

const InterviewItem = ({
  Tag,
  dateCreated,
  status,
  dueDate,
  step: { name, scheduledTime, duration, id },
}: Props) => {
  const getAction = () => {
    const interviewEndTime = add(new Date(dueDate), {
      minutes: duration,
    })

    if (
      [
        JobApplicantStepStatus.NOT_STARTED,
        JobApplicantStepStatus.IN_PROGRESS,
      ].includes(status)
    )
      if (new Date() <= interviewEndTime)
        return (
          <Button
            color="primary"
            component={Link}
            // @ts-ignore placebo-issue
            rel="opener"
            // @ts-ignore placebo-issue
            target="_blank"
            to={routes.waitingForHost.replace(":interviewId", String(id))}
          >
            Join Interview
          </Button>
        )

    return null
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="flex gap-1.5 flex-wrap">
          <Typography color="onSurface.800" variant="strong">
            {name}
          </Typography>

          {Tag}
          <Typography
            className="md:ml-auto"
            color="onSurface.500"
            variant="smallBody"
          >
            Posted on {format(new Date(dateCreated), "do MMM yy")}
          </Typography>
        </div>
        <Typography color="onSurface.400" variant="smallBody">
          Interview
        </Typography>

        {scheduledTime && (
          <ListItem icon={<Clock />}>
            <Typography
              color="onSurface.400"
              display="inline"
              variant="smallBody"
            >
              {new Date(scheduledTime) < new Date()
                ? "Was scheduled"
                : "Scheduled"}{" "}
              for{" "}
            </Typography>
            <Typography display="inline" variant="strongSmallBody">
              {format(new Date(scheduledTime), "dd MMMM yyyy',' hh:mm aa")}
            </Typography>
          </ListItem>
        )}
        <div className="mt-3 flex justify-end gap-1">{getAction()}</div>
      </div>
    </>
  )
}

export default InterviewItem
