import { Radio, Typography } from "@suraasa/placebo-ui-legacy"
import { TRANSITION_DURATION } from "components/ProfileCompletion/constants"
import SelectableCard from "components/ProfileCompletion/SelectableCard"
import { Form } from "components/ProfileCompletion/utils"
import { Controller, useFormContext } from "react-hook-form"

const AreYouCurrentlyTeaching = ({
  handleClick,
}: {
  handleClick: () => void
}) => {
  const { control } = useFormContext<Form>()

  const handleSubmit = () =>
    setTimeout(() => {
      handleClick()
    }, TRANSITION_DURATION)

  return (
    <div>
      <Typography variant="strong">Are you currently teaching?</Typography>
      <div className="mt-2 grid gap-2 md:grid-cols-2">
        <Controller
          control={control}
          name="areYouCurrentlyTeaching"
          render={({ field: { value, onChange } }) => (
            <SelectableCard
              className="col-span-1 flex items-center gap-1"
              selected={value === true}
              onClick={() => {
                onChange(true)
                handleSubmit()
              }}
            >
              <Radio checked={value === true} />
              <Typography color="onSurface.800" variant="subtitle2">
                Yes, I am currently teaching
              </Typography>
            </SelectableCard>
          )}
        />

        <Controller
          control={control}
          name="areYouCurrentlyTeaching"
          render={({ field: { value, onChange } }) => (
            <SelectableCard
              className="col-span-1 row-start-2 flex items-center gap-1"
              selected={value === false}
              onClick={() => {
                onChange(false)
                handleSubmit()
              }}
            >
              <Radio checked={value === false} />
              <Typography color="onSurface.800" variant="subtitle2">
                No, but I would love to
              </Typography>
            </SelectableCard>
          )}
        />
      </div>
    </div>
  )
}

export default AreYouCurrentlyTeaching
