import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card"
import { Avatar, Button, cn, Divider, Typography } from "@suraasa/placebo-ui"
import { CoverStyle } from "api/resources/profile/types"
import clsx from "clsx"
import { motion } from "framer-motion"
import { GlobalContext } from "GlobalState"
import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { DEFAULT_PROFILE_COVER } from "types"
import { getAuthInfo } from "utils/auth"
import { buildUserName, getCoverImageURL, getPlatformURL } from "utils/helpers"

const learnProfileLink = `${import.meta.env.VITE_LEARN_PLATFORM_URL}/profile`

enum MenuOptions {
  suraasaProfile = "Suraasa Profile",
  myCertificates = "My Certificates",
  mySchedule = "My Schedule",
  ordersAndPayments = "Orders and Payments",
  settings = "Settings",
  helpCenter = "Help Center",
  signOut = "Sign Out",
}

const ProfileMenuButton = ({ handleLogout }: { handleLogout: () => void }) => {
  const menuLink = [
    { label: MenuOptions.suraasaProfile, link: learnProfileLink },
    {
      label: MenuOptions.myCertificates,
      link: getPlatformURL("learn", "/certificates"),
    },
    {
      label: MenuOptions.mySchedule,
      link: getPlatformURL("learn", "/my-schedule"),
    },
    {
      label: MenuOptions.ordersAndPayments,
      link: getPlatformURL("learn", "/orders"),
    },
    { label: MenuOptions.settings, link: getPlatformURL("learn", "/settings") },
    {
      label: MenuOptions.helpCenter,
      link: getPlatformURL("sso", "/help"),
    },
    { label: MenuOptions.signOut, onClick: handleLogout },
  ]

  const [open, setOpen] = useState(false)

  const authInfo = getAuthInfo()
  const userName = authInfo ? buildUserName(authInfo.user) : ""
  const { profile } = useContext(GlobalContext)
  const coverStyle = profile.coverStyle as CoverStyle

  return (
    <HoverCard
      openDelay={100}
      closeDelay={100}
      onOpenChange={() => setOpen(!open)}
      open={open}
    >
      <HoverCardTrigger asChild>
        <Button
          variant="text"
          className={clsx(
            "!rounded-md !p-0.75 hover:bg-onSurface-100 focus:!ring-0",
            { "!bg-primary-50": open }
          )}
        >
          <Avatar
            name={userName}
            className="size-4 cursor-pointer border border-onSurface-200 focus:!ring-0"
            src={authInfo && authInfo.user?.photo}
          />
        </Button>
      </HoverCardTrigger>
      <HoverCardContent asChild>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          className="z-50 w-[266px] rounded-lg border border-onSurface-200 bg-white shadow-lg"
        >
          <Link
            className="relative mb-[101px] block w-full px-0.5 pt-0.5"
            to={learnProfileLink}
          >
            <div
              style={{
                backgroundImage: `url(${getCoverImageURL(
                  coverStyle || DEFAULT_PROFILE_COVER,
                  true
                )})`,
              }}
              className="relative h-[65px] w-full rounded bg-cover text-black"
            />
            <div className="absolute left-0 top-4.5 flex w-full flex-col items-center">
              <Avatar
                src={authInfo?.user.photo}
                name={userName}
                className="mb-1 size-9"
              />
              <Typography variant="title4">{userName}</Typography>
              <Typography variant="smallBody" className="text-onSurface-500">
                View Profile
              </Typography>
            </div>
          </Link>
          <Divider className="my-1 bg-onSurface-200" />
          <div className="px-1">
            {menuLink.map(route => {
              const styles = cn(
                "w-full rounded p-1 text-start text-onSurface-900 hover:bg-[#F1F5F9]",
                {
                  "text-critical-500 hover:bg-critical-50":
                    route.label === MenuOptions.signOut,
                  "bg-primary-50 text-primary-500":
                    route.link === location.pathname,
                }
              )
              if (route.onClick) {
                return (
                  <button
                    key={route.label}
                    onClick={() => {
                      route.onClick()
                      setOpen(false)
                    }}
                    className={styles}
                  >
                    <Typography variant="strongSmallBody">
                      {route.label}
                    </Typography>
                  </button>
                )
              }
              return (
                <Link key={route.label} to={route.link}>
                  <button className={styles}>
                    <Typography variant="strongSmallBody">
                      {route.label}
                    </Typography>
                  </button>
                </Link>
              )
            })}
          </div>
          <Divider className="my-1 bg-onSurface-200" />
          <div className="flex items-center justify-start gap-0.5 pb-1 pl-2 text-left text-xs font-normal leading-[15.6px] text-onSurface-500">
            <Link
              to={getPlatformURL("suraasa", "/terms-of-use")}
              className="hover:underline"
            >
              Terms and Conditions
            </Link>
            <span>•</span>
            <Link
              to={getPlatformURL("suraasa", "/privacy-policy")}
              className="hover:underline"
            >
              Privacy Policy
            </Link>
          </div>
          {/* <Typography className="pb-0.5 pl-2 !text-[0.75rem] text-onSurface-400">
            {APP_VERSION}
          </Typography> */}
        </motion.div>
      </HoverCardContent>
    </HoverCard>
  )
}

export default ProfileMenuButton
