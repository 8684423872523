/* eslint-disable no-undef */
import InvalidMeeting from "components/InvalidMeeting"
import Navbar from "components/shared/Navbar"
import React, { useEffect } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate, useSearchParams } from "react-router-dom"
import useScript from "utils/hooks/useScript"

const useStyles = createUseStyles({
  huddleFrame: {
    width: "100%",
    height: "calc(100vh - 62px)",
    "& > iframe": {
      border: "none",
    },
  },
})

// NOTE: TLD stands for top level domain (like .com, .in)
const isSecure = (url: URL) => url.protocol === "https:"
const isHuddleUrl = (url: URL) => {
  const [TLD, domain] = url.hostname.split(".").reverse()
  return domain === "huddle01" && TLD === "com"
}

const isURLValid = (link: string) => {
  try {
    return isSecure(new URL(link)) && isHuddleUrl(new URL(link))
  } catch (error) {
    console.error(error)
    return false
  }
}

const AttemptInterview = () => {
  const [searchParams] = useSearchParams()
  const classes = useStyles()

  const navigate = useNavigate()

  const link = decodeURIComponent(searchParams.get("meeting-link") || "")
  const status = useScript("https://huddll01-iframe-api.netlify.app/api.min.js")

  useEffect(() => {
    const huddleEl = document.getElementById("huddle01")
    if (status === "ready" && huddleEl) {
      // @ts-ignore typescript doesn't know that HuddleApp is defined by the injected script
      const huddleApp = new HuddleApp({
        config: {
          parentEl: huddleEl,
          iframeConfig: {
            src: link,
            width: "100%",
            height: "100%",
          },
        },
      })

      const handleEvent = (event: MessageEvent<any>) => {
        if (event.data.event === "me-left") {
          window.close()
        }
      }

      window.addEventListener("message", handleEvent)

      return () => {
        window.removeEventListener("message", handleEvent)
        huddleApp.destroy()
      }
    }
  }, [link, status, navigate])

  if (!link || !isURLValid(link)) return <InvalidMeeting />

  return (
    <div>
      <Navbar gutterBottom={false} hideBackButton />
      <div className={classes.huddleFrame} id="huddle01" title="Interview" />
    </div>
  )
}

export default AttemptInterview
