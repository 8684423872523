import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
} from "@suraasa/placebo-ui-legacy"
import React from "react"
import { ToggleValue } from "utils/hooks/useToggle"

type Props = {
  toggle: ToggleValue
  handleAccept: () => void
} & Pick<DialogProps, "open">

const AcceptOfferDialog = ({ open, toggle, handleAccept }: Props) => (
  <Dialog open={open} width={358} onRequestClose={() => toggle()}>
    <DialogTitle>Accept Offer</DialogTitle>
    <DialogContent>
      Are you sure you want to accept this Job Offer?
    </DialogContent>
    <DialogFooter
      actions={{
        primary: {
          label: "Accept Offer",
          onClick: handleAccept,
        },
      }}
    />
  </Dialog>
)

export default AcceptOfferDialog
