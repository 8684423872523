import { Select, Typography } from "@suraasa/placebo-ui-legacy"
import { GRADES } from "components/ProfileCompletion/constants"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

const Grades = ({ label, name }: { label: string; name: string }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()

  return (
    <div>
      <Typography className="mb-2" variant="strong">
        {label}
      </Typography>
      <div className="grid md:grid-cols-2">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <Select
              className="col-span-1"
              error={Boolean(errors[name])}
              helperText={errors[name]?.message as string}
              options={GRADES}
              placeholder="Ex. Elementary"
              value={value}
              fullWidth
              isMulti
              required={false}
              onChange={option => {
                onChange(option)
              }}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      </div>
    </div>
  )
}

export default Grades
