import { Button, Typography } from "@suraasa/placebo-ui-legacy"
import { Copy } from "iconoir-react"
import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  copyLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.colors.onSurface[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "pre",
    },
  },

  copyToClipboard: {
    background: "#fff",
  },
}))

type Props = { url: string }

const CopyText = ({ url }: Props) => {
  const classes = useStyles()

  const [copied, setCopied] = useState(false)

  function copyToClipboard() {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <div className={classes.copyLink}>
      <Typography variant="body">{url}</Typography>
      {/* @ts-expect-error type issues */}
      <CopyToClipboard text={url} onCopy={() => copyToClipboard()}>
        <Button
          className={classes.copyToClipboard}
          startAdornment={<Copy />}
          variant="text"
        >
          {copied ? "Copied!" : "Copy"}
        </Button>
      </CopyToClipboard>
    </div>
  )
}

export default CopyText
