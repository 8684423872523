import { Button, Typography } from "@suraasa/placebo-ui-legacy"
import Illustration from "assets/illustrations/broke-connection.svg"
import clsx from "clsx"
import Navbar from "components/shared/Navbar"
import React from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    height: "100%",
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const InvalidMeeting = () => {
  const classes = useStyles()

  return (
    <>
      <Navbar />
      <div
        className={clsx(
          "flex flex-col-reverse items-center justify-evenly gap-2 px-2 md:flex-row",
          classes.root
        )}
      >
        <div className="flex flex-col gap-2">
          <Typography color="onSurface.800" variant="largeTitle">
            Ooooops!
          </Typography>
          <Typography color="onSurface.500" variant="largeBody">
            The meeting link is invalid
          </Typography>
          <Typography color="onSurface.800" variant="largeBody">
            If the issue persists, please reach out to us.
          </Typography>
          <div className="mt-3 flex flex-col gap-2">
            <Button component={Link} size="sm" to={routes.home}>
              Return to Dashboard
            </Button>
            <Button variant="text">Contact us</Button>
          </div>
        </div>
        <img src={Illustration} alt="" className={classes.image} />
      </div>
    </>
  )
}

export default InvalidMeeting
