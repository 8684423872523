import { Typography } from "@suraasa/placebo-ui-legacy"
import React, { useContext } from "react"
import SchoolProfileContext from "views/schoolProfile/context"

import CheckTile from "../CheckTile"

function Perks() {
  const { perks } = useContext(SchoolProfileContext)

  return (
    <>
      <div className="flex items-center justify-between">
        <Typography
          className="mb-3 mt-0.5"
          color="primary.500"
          variant="preTitle"
        >
          Perks
        </Typography>
      </div>

      {perks.data.length ? (
        <div className="flex flex-col flex-wrap sm:flex-row sm:justify-start">
          {perks.data.map(item => (
            <CheckTile key={item.id} label={item.name} />
          ))}
        </div>
      ) : (
        <Typography
          className="mb-0.5"
          color="onSurface.400"
          variant="smallBody"
        >
          Looks like there's nothing here
        </Typography>
      )}
    </>
  )
}

export default Perks
