import { Typography } from "@suraasa/placebo-ui-legacy"
import Completed from "assets/stepper/completed.svg"
import InProgress from "assets/stepper/in-progress.svg"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"
import { isLocalhost } from "utils/constants"

import { STEPS } from "./constants"
import { ProfileCompletionContextType } from "./utils"

const useStyles = createUseStyles(theme => ({
  timeline: {
    position: "sticky",
    top: 0,
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
    // {classes.timelineItem} last child shouldn't have connector
    "& .TimelineItem-root:last-child:after": {
      border: "none",
      width: "0",
    },
  },

  timelineItem: {
    flex: 1,
    height: "100%",
    justifyContent: "space-around",

    "&:after": {
      width: "1px",
      height: "inherit",
      content: "''",
      position: "relative",
      left: "-32px",
      zIndex: "-1",
      border: `0.5px solid ${theme.colors.onSurface[200]}`,
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        display: "flex",
        justifyContent: "center",
        width: "inherit",
        left: "50%",
        top: "32px",
        height: "1px",
        content: "''",
        zIndex: "-1",
        position: "relative",
        borderTop: `0.5px solid ${theme.colors.onSurface[300]}`,
        [theme.breakpoints.down("xs")]: {
          top: "24px",
        },
      },
    },
  },

  icon: {
    height: 48,
    width: 48,
    [theme.breakpoints.down("xs")]: {
      height: 32,
      width: 32,
    },
  },

  notCompleted: {
    background: theme.colors.surface[300],
    borderRadius: "50%",
  },
}))

const timeline = [
  {
    text: "Personal",
    subtext: "Details",
    inProgress: STEPS.PersonalDetails,
    completed: STEPS.MarketingData,
  },
  {
    text: "Work",
    subtext: "Preferences",
    inProgress: STEPS.MarketingData,
    completed: STEPS.WorkExperience,
  },
  {
    text: "Work",
    subtext: "Experience",
    inProgress: STEPS.WorkExperience,
    completed: STEPS.AcademicDetails,
  },
  {
    text: "Academic",
    subtext: "Details",
    inProgress: STEPS.AcademicDetails,
    completed: STEPS.UploadResume,
  },
  {
    text: "Resume",
    subtext: "Upload",
    inProgress: STEPS.UploadResume,
    completed: STEPS.Congratulations,
  },
]

type TimelineItemProps = {
  activeStep: ProfileCompletionContextType["step"]
  onClick: () => void
} & (typeof timeline)[0]

const TimelineItem = ({
  text,
  subtext,
  activeStep,
  inProgress,
  onClick,
  completed,
}: TimelineItemProps) => {
  const classes = useStyles()

  const getIcon = () => {
    if (completed <= activeStep)
      return <img src={Completed} alt="" className={classes.icon} />
    if (inProgress > activeStep)
      return <div className={clsx(classes.notCompleted, classes.icon)} />
    return <img src={InProgress} alt="" className={classes.icon} />
  }

  return (
    <div
      className={clsx(
        classes.timelineItem,
        "flex flex-col-reverse items-center gap-1 md:flex-row md:items-start",
        "TimelineItem-root"
      )}
    >
      <div className="mt-0.5 flex flex-col items-center justify-start md:items-end">
        <Typography variant="strong">{text}</Typography>
        <Typography color="onSurface.500" variant="body">
          {subtext}
        </Typography>
      </div>

      <button onClick={onClick} disabled={!isLocalhost}>
        {getIcon()}
      </button>
    </div>
  )
}

const Timeline = ({
  step,
  onClick,
}: {
  step: ProfileCompletionContextType["step"]
  onClick: (step: ProfileCompletionContextType["step"]) => void
}) => {
  const classes = useStyles()

  return (
    <div className={clsx("flex items-end md:flex-col", classes.timeline)}>
      {timeline.map((item, i) => (
        <TimelineItem
          {...item}
          activeStep={step}
          key={i}
          onClick={() => onClick(item.inProgress)}
        />
      ))}
    </div>
  )
}

export default Timeline
