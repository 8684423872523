import {
  Button,
  Container,
  Theme,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import ProfileCompletion from "components/ProfileCompletion"
import { GlobalContext } from "GlobalState"
import { InfoCircle } from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { getAuthInfo } from "utils/auth"
import { isProfileComplete } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"

const useStyles = createUseStyles(theme => ({
  root: {
    background: theme.colors.warning[100],
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.colors.warning[600]}`,
    borderTop: `1px solid ${theme.colors.warning[600]}`,
  },
}))

const ProfileNotCompletedBanner = () => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const authInfo = getAuthInfo()

  const [profileCompletionDialog, toggleProfileCompletionDialog] = useToggle()

  const { stepsOverview } = useContext(GlobalContext)

  if (!authInfo) {
    return null
  }

  if (!stepsOverview.loading && !isProfileComplete(stepsOverview)) {
    return (
      <>
        {stepsOverview && profileCompletionDialog && (
          <ProfileCompletion
            open={profileCompletionDialog}
            toggle={toggleProfileCompletionDialog}
          />
        )}
        {stepsOverview !== null && (
          <div className={classes.root}>
            <Container>
              <div className="flex flex-col justify-between gap-1 sm:flex-row sm:items-center">
                <div className="flex items-center gap-1">
                  <InfoCircle
                    color={theme.colors.warning[700]}
                    className="shrink-0"
                    height={20}
                    width={20}
                  />
                  <Typography color="warning.700" variant="smallBody">
                    Complete your profile to apply for jobs!
                  </Typography>
                </div>
                <Button
                  color="warning"
                  variant="outlined"
                  size="sm"
                  className="!w-full !border-warning-600 sm:!w-auto"
                  onClick={() => toggleProfileCompletionDialog()}
                >
                  <Typography color="warning.700" variant="smallBody">
                    Complete Profile
                  </Typography>
                </Button>
              </div>
            </Container>
          </div>
        )}
      </>
    )
  }

  return null
}

export default ProfileNotCompletedBanner
