import { Button } from "@suraasa/placebo-ui-legacy"
import { ArrowLeft } from "iconoir-react"
import React, { useCallback } from "react"
import { useNavigate } from "react-router"

export type BackButtonProps = {
  onBack?: () => void
  delta?: number
  className?: string
  disabled?: boolean
}

const BackButton = ({
  className,
  delta = -1,
  onBack,
  disabled = false,
  ...otherProps
}: BackButtonProps) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (onBack) onBack()
    else if (window.history.state !== null && window.history.state.idx >= 1)
      navigate(delta)
    else navigate("/")
  }, [navigate, onBack, delta])

  return (
    <Button
      className={className}
      disabled={disabled}
      startAdornment={<ArrowLeft />}
      variant="text"
      onClick={handleClick}
      {...otherProps}
    >
      Back
    </Button>
  )
}

export default BackButton
