import { Checkbox, Radio, Typography } from "@suraasa/placebo-ui-legacy"
import api from "api"
import {
  AssessmentPerformance,
  QuestionType,
} from "api/resources/assessments/types"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  questionAttemptCard: {
    background: "white",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },

  optionContainer: {
    borderRadius: theme.spacing(0.5),
    width: "100%",
  },
}))

function QuestionAttemptCard({
  question,
  questionNumber,
  onMark,
  jobApplicantStepId,
}: {
  onMark: (response: number[]) => void
  question: AssessmentPerformance
  questionNumber: number
  jobApplicantStepId?: string
}) {
  const classes = useStyles()
  const navigate = useNavigate()
  const markAnswer = (optionIdx: number) => {
    const oldResponse = question.response
    let newResponse = question.response

    if (question.response.includes(optionIdx)) {
      newResponse = question.response.filter(option => optionIdx !== option)
    } else if (question.question.questionType === QuestionType.SINGLE_CORRECT) {
      newResponse = [optionIdx]
    } else newResponse.push(optionIdx)

    if (jobApplicantStepId)
      api.assessments
        .markAnswer({
          urlParams: {
            jobApplicantStepId,
            questionId: question.question.id,
          },
          data: {
            response: newResponse,
          },
        })
        .then(res => {
          if (res.isSuccessful) {
            if ("autoSubmitted" in res.data) {
              toast.success(
                "Time's up. Your assessment was submitted automatically"
              )
              navigate(-1)
            }
          } else {
            toast.error("Your response could not be saved.")
            // Rollback if API fails
            // Add sentry log here
            onMark(oldResponse)
          }
        })

    onMark(newResponse)
  }

  return (
    <div className={clsx(classes.questionAttemptCard, "px-2.5 py-3 mb-2")}>
      <Typography className="mb-1" variant="strongSmallBody">
        Question {questionNumber}
      </Typography>

      <Typography variant="body">{question.question.text}</Typography>

      <div className="my-3.5 flex flex-col gap-1">
        {question.question.options.map((option, index) => (
          <div
            className={clsx(
              classes.optionContainer,
              "flex items-center py-0.5 px-0.5"
            )}
            key={index}
          >
            {question.question.questionType === QuestionType.SINGLE_CORRECT ? (
              <>
                <Radio
                  checked={question.response.includes(index)}
                  onChange={() => markAnswer(index)}
                />
                <button tabIndex={-1} onClick={() => markAnswer(index)}>
                  <Typography className="ml-1.25" variant="body">
                    {option}
                  </Typography>
                </button>
              </>
            ) : (
              <>
                <Checkbox
                  checked={question.response.includes(index)}
                  defaultChecked={undefined}
                  value={option}
                  onChange={() => markAnswer(index)}
                />
                <button tabIndex={-1} onClick={() => markAnswer(index)}>
                  <Typography className="ml-1.25" variant="body">
                    {option}
                  </Typography>
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default QuestionAttemptCard
