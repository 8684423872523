import { Container, Typography } from "@suraasa/placebo-ui-legacy"

import Navbar from "./shared/Navbar"

type Props = {
  message: string
}
const ErrorPage = ({ message }: Props) => {
  return (
    <>
      <title>Suraasa</title>

      <Navbar hideBackButton />
      <Container>
        <div className="grid place-items-center">
          <Typography variant="title3">{message}</Typography>
        </div>
      </Container>
    </>
  )
}

export default ErrorPage
