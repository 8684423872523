import { Button } from "@suraasa/placebo-ui-legacy"
import api from "api"
import axios from "axios"
import { STEPS } from "components/ProfileCompletion/constants"
import Heading from "components/ProfileCompletion/Heading"
import { ProfileCompletionContext } from "components/ProfileCompletion/utils"
import AsyncBuilder from "components/shared/AsyncBuilder"
import { GlobalContext } from "GlobalState"
import { useContext, useState } from "react"
import { toast } from "react-toastify"

import DragNDropPDF from "./DragNDropPDF"

type Props = {
  afterCompletion?: () => void
}

const UploadResume = (props: Props) => {
  return (
    <div className="flex flex-col">
      <Heading
        subtitle="A well formatted and up to date resume makes your application stand out"
        title="Upload your Resume"
      />

      <AddResume {...props} />
    </div>
  )
}

export default UploadResume

const CancelToken = axios.CancelToken
let cancelAxiosRequest: () => void

const AddResume = (props: Props) => {
  const { setStep } = useContext(ProfileCompletionContext)
  const { setStepsOverview } = useContext(GlobalContext)

  const [resume, setResume] = useState<File | null>(null)

  const [uploadPercentage, setUploadPercentage] = useState<number>()

  const handleFileUpload = (files: File) => {
    setResume(files)
  }

  const submit = async () => {
    if (!resume) return

    const uploadFile = new FormData()
    uploadFile.append("resume", resume)

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },

      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        if (percent <= 100 && percent > 0) {
          setUploadPercentage(percent)
        }
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelAxiosRequest = c
      }),
    }

    const res = await api.profile.update({
      data: uploadFile,
      ...options,
    })

    if (res.isSuccessful) {
      clearInput()
      markProfileAsComplete()
    } else {
      cancelAxiosRequest()
      setUploadPercentage(undefined)
      toast.error("Something went wrong while uploading your resume")
    }
  }

  const markProfileAsComplete = () => {
    setStepsOverview({
      marketingFormDataExists: true,
      profileDataExists: true,
      marketingSignupComplete: true,
      qualificationDataExists: true,
      resumeExists: true,
      loading: false,
    })
    if (props.afterCompletion) props.afterCompletion()
    setStep(STEPS.Congratulations)
  }

  const clearInput = () => {
    const inputEl = document.getElementById(
      "resume-pdf-input"
    ) as HTMLInputElement
    if (inputEl) {
      inputEl.value = ""
    }

    setUploadPercentage(undefined)
    setResume(null)
  }

  return (
    <div>
      <div className="max-w-[400px]">
        <DragNDropPDF
          inputId="resume-pdf-input"
          allowedExtensions={[".pdf"]}
          instructions={["Only .pdf files. 10MB max file size."]}
          maxSize={10}
          uploadProgress={uploadPercentage}
          onChange={files => {
            if (files?.length) {
              handleFileUpload(files[0] as File)
            }
            if (!files) {
              clearInput()
            }
          }}
        />
      </div>
      <AsyncBuilder
        handler={submit}
        render={({ loading, onClick }) => (
          <Button
            className="mt-3"
            onClick={onClick}
            loading={loading}
            disabled={!resume}
          >
            Upload Resume
          </Button>
        )}
      />
    </div>
  )
}
