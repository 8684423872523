import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { JobOfferStatus } from "api/resources/jobs/types"
import clsx from "clsx"
import LoadingOverlay from "components/shared/LoadingOverlay"
import React, { useCallback, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import OTPInput from "react-otp-input"
import { useNavigate, useParams } from "react-router"
import { useTimer } from "react-timer-hook"
import { getAuthInfo } from "utils/auth"
import { GA, GA_EVENTS } from "utils/googleAnalytics"
import { handleErrors } from "utils/helpers"
import { ToggleValue } from "utils/hooks/useToggle"
import { routes } from "utils/routes"

type Props = {
  toggle: ToggleValue
  afterSubmit: () => void
  school: string
  status: JobOfferStatus.ACCEPTED | JobOfferStatus.REJECTED
} & Pick<DialogProps, "open">

type Form = {
  otp: string
}

const useStyles = createUseStyles(theme => ({
  containerStyle: {
    marginTop: theme.spacing(4),
    justifyContent: "space-between",
  },
  inputStyle: {
    borderRadius: 4,
    width: `41px !important`,
    height: 50,
    border: `2px solid ${theme.colors.onSurface[500]}`,
    ...theme.typography.title2,
  },
  focusStyle: {
    outline: `2px solid ${theme.colors.interactive[400]}`,
    outlineOffset: "1px",
  },
  errorStyle: {
    border: `2px solid ${theme.colors.critical[500]}`,
  },
}))

function Timer({
  expiryTimestamp,
  onExpire,
}: {
  expiryTimestamp: string
  onExpire: () => void
}) {
  const { seconds, minutes } = useTimer({
    expiryTimestamp: new Date(expiryTimestamp),
    onExpire,
  })

  return (
    <>
      {`${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`}
    </>
  )
}

const OTPDialog = ({ open, toggle, afterSubmit, status, school }: Props) => {
  const {
    control,
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<Form>()

  const navigate = useNavigate()

  const { jobOfferId } = useParams()

  const classes = useStyles()

  const [otpGenerated, setOtpGenerated] = useState<boolean | null>(null)
  const [canResendOtp, setCanResendOtp] = useState(false)
  const [resendAfter, setResendAfter] = useState<string>()

  const onSubmit = handleSubmit(async formData => {
    if (!jobOfferId) return

    const res = await api.jobs.jobOffers.verifyOtp({
      urlParams: { jobOfferId },
      data: { otp: formData.otp, status },
    })

    if (res.isSuccessful) {
      reset({})
      if (status === JobOfferStatus.ACCEPTED) {
        GA.trackEvent(GA_EVENTS.job_offer_accepted, {
          job_id: jobOfferId,
          school_name: school,
        })
        afterSubmit()
      } else if (status === JobOfferStatus.REJECTED) {
        GA.trackEvent(GA_EVENTS.job_offer_rejected, {
          job_id: jobOfferId,
          school_name: school,
        })
        navigate(`${routes.home}?tab=Job+Offers`)
      }
    } else {
      handleErrors(setError, res.errors)
    }
  })

  const generateOtp = useCallback(async () => {
    if (!jobOfferId) return

    const res = await api.jobs.jobOffers.generateOtp({
      urlParams: { jobOfferId },
    })
    if (res.isSuccessful) {
      setOtpGenerated(true)
      setResendAfter(res.data.resendAt)
      setCanResendOtp(false)
    } else {
      setOtpGenerated(false)
      if (res.errors.fieldErrors) {
        setResendAfter(res.errors.fieldErrors.resendAt as string)
        setCanResendOtp(false)
      }
    }
  }, [jobOfferId])

  const renderTimer = useCallback(() => {
    if (!canResendOtp && resendAfter) {
      return (
        <Timer
          expiryTimestamp={resendAfter}
          onExpire={() => setCanResendOtp(true)}
        />
      )
    }
    return (
      <Button
        disabled={!canResendOtp}
        size="sm"
        type="button"
        variant="text"
        onClick={generateOtp}
      >
        Send Again
      </Button>
    )
  }, [resendAfter, canResendOtp, generateOtp])

  useEffect(() => {
    generateOtp()
  }, [generateOtp])

  return (
    <Dialog
      open={open}
      width={358}
      onAfterClose={() => reset({})}
      onRequestClose={() => toggle()}
    >
      <DialogTitle>Verify Your Email</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {otpGenerated === null && <LoadingOverlay />}
          <Typography color="onSurface.800" display="inline">
            OTP sent to{" "}
          </Typography>
          <Typography color="onSurface.800" display="inline" variant="strong">
            {getAuthInfo()?.user.email}
          </Typography>
          <Controller
            control={control}
            name="otp"
            render={({ field }) => (
              <OTPInput
                numInputs={6}
                {...field}
                containerStyle="justify-start gap-1"
                inputStyle={clsx(
                  "h-[50px] !w-[41px] rounded-md border-2 border-solid border-onSurface-500 focus:ring-2 focus:ring-interactive-500 focus:ring-offset-1",
                  {
                    "!border-critical-500 !text-critical-500": Boolean(
                      errors.otp
                    ),
                  }
                )}
                inputType="number"
                renderInput={props => <input {...props} />}
                shouldAutoFocus
              />
            )}
          />
          {Boolean(errors.otp) && (
            <Typography color="critical.500" variant="smallBody">
              {errors.otp?.message}
            </Typography>
          )}
          <div className="mt-6">
            <Typography color="onSurface.800" display="inline">
              {!canResendOtp ? "You can resend otp in" : "Didn't receive OTP?"}{" "}
            </Typography>
            {renderTimer()}
          </div>
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Verify",
            onClick: onSubmit,
            loading: isSubmitting,
          },
        }}
      />
    </Dialog>
  )
}

export default OTPDialog
