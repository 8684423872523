import { Typography } from "@suraasa/placebo-ui-legacy"
import React from "react"
import { createUseStyles } from "react-jss"

type Props = {
  title: string
  subtitle: string
}

const useStyles = createUseStyles(theme => ({
  root: {
    "& *": {
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "start",
      },
    },
  },
}))

const Heading = ({ title, subtitle }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className="mb-0.75" variant="title2">
        {title}
      </Typography>
      <Typography className="mb-6 md:mb-3" variant="largeBody">
        {subtitle}
      </Typography>
    </div>
  )
}

export default Heading
