import { Radio, Typography } from "@suraasa/placebo-ui-legacy"
import { TRANSITION_DURATION } from "components/ProfileCompletion/constants"
import SelectableCard from "components/ProfileCompletion/SelectableCard"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

const LookingForJobs = ({
  handleClick,
}: {
  handleClick: (arg0: boolean) => void
}) => {
  const { control } = useFormContext()

  const handleSubmit = (value: boolean) => {
    setTimeout(() => {
      handleClick(value)
    }, TRANSITION_DURATION)
  }

  return (
    <div>
      <Typography variant="strong">Are you looking for jobs?</Typography>

      <div className="mt-2 grid gap-2 md:grid-cols-2">
        <Controller
          control={control}
          name="lookingForJobs"
          render={({ field: { value, onChange } }) => (
            <SelectableCard
              className="col-span-1 flex items-center gap-1"
              selected={value}
              onClick={() => {
                onChange(true)
                handleSubmit(true)
              }}
            >
              <Radio checked={value} />
              <Typography color="onSurface.800" variant="subtitle2">
                Yes, I am looking for a job
              </Typography>
            </SelectableCard>
          )}
        />

        <Controller
          control={control}
          name="lookingForJobs"
          render={({ field: { value, onChange } }) => (
            <SelectableCard
              className="col-span-1 row-start-2 flex items-center gap-1"
              selected={value === false}
              onClick={() => {
                onChange(false)
                handleSubmit(false)
              }}
            >
              <Radio checked={value === false} />
              <Typography color="onSurface.800" variant="subtitle2">
                No, I am not looking for jobs
              </Typography>
            </SelectableCard>
          )}
        />
      </div>
    </div>
  )
}

export default LookingForJobs
