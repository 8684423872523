import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import {
  InviteStatus,
  InviteUpdateResponse,
  Reason,
} from "api/resources/jobs/types"
import SharedDialog from "components/SharedDialog"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"

type Props = {
  handleClose: ToggleValue
  afterSubmit?: (res: InviteUpdateResponse) => void
  mode: "withdraw" | "invitation"
  jobPosition: string
  schoolName: string
  subjectName?: string
  onWithdrawHandler?: (reason: Reason[]) => Promise<void>
  /**
   * jobApplicant is needed only
   * in the case where we need to add interview
   * Note: This can be either string or number
   * if it comes from backend --> number
   * if it comes from url params --> string
   */
  jobApplicantId: string | number
} & Pick<DialogProps, "open">

const rejectionReasons = [
  { key: "0", display: "I’m looking for more salary" },
  { key: "1", display: "School location is not adequate for me" },
  { key: "2", display: "I don’t have experience in this curriculum" },
  { key: "3", display: "I don’t teach this subject" },
  { key: "4", display: "I’m already working at a higher position" },
  { key: "5", display: "I’m currently working in this school" },
  { key: "6", display: "Other Reasons" },
] as const

type Form = {
  "0": boolean
  "1": boolean
  "2": boolean
  "3": boolean
  "4": boolean
  "5": boolean
  "6": boolean
  feedback: string
}

const getConfig = (
  mode: Props["mode"],
  {
    schoolName,
    jobPosition,
    subjectName,
  }: Pick<Props, "schoolName" | "jobPosition" | "subjectName">
) => {
  switch (mode) {
    case "withdraw":
      return {
        buttonText: "Withdraw Application",
        title: "Withdraw Application",
        toastSuccessMessage: "Application Withdrawn",
        subtitle:
          "Please tell us the reason for withdrawing this Job Application",
        body: (
          <>
            Are you sure you want to withdraw your application from{" "}
            <b>{schoolName}’s</b> job opening for&nbsp;
            <b>
              {jobPosition}, {subjectName}
            </b>
            ?
          </>
        ),
      }
    case "invitation":
      return {
        buttonText: "Reject",
        title: "Reject Invite",
        toastSuccessMessage: "Invitation Rejected",
        subtitle: "Please tell us the reason for rejecting this invite",
        body: (
          <>
            Are you sure you want to reject <b>{schoolName}’s</b> invite
            for&nbsp;<b>{jobPosition}</b>?
          </>
        ),
      }

    default:
      return {
        buttonText: "",
        title: "",
        subtitle: "",
        body: "",
      }
  }
}

const RejectApplicationDialog = ({
  open,
  jobApplicantId,
  afterSubmit,
  handleClose,
  onWithdrawHandler,
  mode,
  schoolName,
  jobPosition,
}: Props) => {
  const [confirmed, setConfirmed] = useState(false)
  const {
    register,
    handleSubmit,
    reset,

    watch,
    formState: { isSubmitting, errors },
  } = useForm<Form>()

  const isOtherReasonsSelected = watch("6")

  const { title, subtitle, buttonText, body, toastSuccessMessage } = getConfig(
    mode,
    {
      schoolName,
      jobPosition,
    }
  )

  const onSubmit = handleSubmit(async formData => {
    // If all values are empty then we show an error
    const isEmpty = Object.values(formData).every(x => Boolean(x) === false)
    if (isEmpty) {
      toast.error("Please select a reason")
      return
    }

    const reasons: { reason: string | undefined; isOther: boolean }[] = []

    for (const [key, value] of Object.entries(formData)) {
      if (value) {
        if (key === "6") continue

        if (key === "feedback")
          reasons.push({ reason: formData.feedback, isOther: true })
        else
          reasons.push({
            reason: rejectionReasons.find(r => r.key === key)?.display,
            isOther: false,
          })
      }
    }

    if (mode === "withdraw") {
      if (onWithdrawHandler) {
        await onWithdrawHandler(reasons)
      }
      return
    }

    const res = await api.jobs.jobApplicant.updateInviteStatus({
      urlParams: {
        jobApplicantId,
      },
      data: {
        status: InviteStatus.REJECTED,
        rejectionReason: reasons,
      },
    })

    if (res.isSuccessful) {
      if (afterSubmit) {
        afterSubmit(res.data)
      }
      toast.success(toastSuccessMessage)
      handleClose()
    } else {
      toast.error(res.errors.message)
    }
  })

  return (
    <>
      <SharedDialog
        actionLabel={buttonText}
        handleClose={handleClose}
        open={open && !confirmed}
        title={title}
        isDestructive
        onAfterClose={() => reset({})}
        onConfirm={() => {
          setConfirmed(true)
        }}
      >
        <Typography variant="smallBody">{body}</Typography>
      </SharedDialog>

      <Dialog
        open={open && confirmed}
        width="md"
        onAfterClose={() => reset({})}
        onRequestClose={() => handleClose()}
      >
        <DialogTitle
          onBack={() => {
            setConfirmed(false)
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography className="mb-2.5 mt-2" variant="strong">
            {subtitle}
          </Typography>
          <form className="flex flex-col gap-2" onSubmit={onSubmit}>
            {rejectionReasons.map(({ key, display }) => (
              <Checkbox {...register(key)} key={key} label={display} />
            ))}
            {/* @ts-expect-error: placebo rows prop error */}
            <TextField
              className="mb-1.5"
              error={isOtherReasonsSelected && Boolean(errors.feedback)}
              helperText={
                isOtherReasonsSelected ? errors.feedback?.message : undefined
              }
              rows={2}
              fullWidth
              multiLine
              {...register("feedback", {
                required: {
                  value: isOtherReasonsSelected,
                  message: "Required",
                },
              })}
              placeholder="Type your reason here"
            />
          </form>
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: buttonText,
              color: "critical",
              onClick: onSubmit,
              loading: isSubmitting,
            },
          }}
        />
      </Dialog>
    </>
  )
}

export default RejectApplicationDialog
