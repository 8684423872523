import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@suraasa/placebo-ui"
import { useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { motion } from "framer-motion"
import { useCallback, useEffect, useRef, useState } from "react"
import { createUseStyles } from "react-jss"
import { NavLink, useLocation } from "react-router-dom"

const useStyles = createUseStyles(() => ({
  isNotification: {
    "& > ul $szh-menu": {
      width: 420,
      padding: 0,
      borderRadius: 12,
      overflow: "hidden",
      textDecoration: "none",
    },
  },
}))

interface NavBackgroundProps {
  containerRef: React.RefObject<HTMLDivElement>
}

export const EXPLORE_MENU_OPENED_EVENT_NAME = "explore-menu-opened"

export const NavBackground = ({ containerRef }: NavBackgroundProps) => {
  const location = useLocation()
  const [activeStyle, setActiveStyle] = useState({ left: 0, width: 0 })
  const navBgRef = useRef<HTMLDivElement>(null)

  const updatePosition = useCallback(() => {
    const container = containerRef.current
    if (!container) return

    const requestId = requestAnimationFrame(() => {
      const activeLink = container.querySelector('[data-active="true"]')
      if (!activeLink) {
        setActiveStyle(p => ({ left: p.left, width: 0 }))
        return
      }

      const { left, width } = activeLink.getBoundingClientRect()
      const containerLeft = container.getBoundingClientRect().left

      setActiveStyle({ left: left - containerLeft, width })
    })

    return requestId
  }, [containerRef])

  useEffect(() => {
    const requestId = updatePosition()

    window.addEventListener("resize", updatePosition)

    return () => {
      window.removeEventListener("resize", updatePosition)

      if (requestId) {
        cancelAnimationFrame(requestId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    const requestId = updatePosition()
    window.addEventListener(
      EXPLORE_MENU_OPENED_EVENT_NAME,
      updatePosition,
      false
    )

    return () => {
      window.removeEventListener(
        EXPLORE_MENU_OPENED_EVENT_NAME,
        updatePosition,
        false
      )

      if (requestId) {
        cancelAnimationFrame(requestId)
      }
    }
  }, [updatePosition])

  return (
    <div
      ref={navBgRef}
      className="absolute h-3.5 rounded-xl bg-primary-50 transition-all duration-300"
      style={{
        left: `${activeStyle.left}px`,
        width: `${activeStyle.width}px`,
        opacity: activeStyle.width ? 1 : 0,
      }}
    />
  )
}

type LinkProps = {
  icon?: React.ReactNode
  activeIcon?: React.ReactElement
  text?: string
} & (
  | {
      href?: never
      isMenu: true
      arrow?: boolean
      menuChildren: React.ReactNode
      isNotification?: boolean
    }
  | {
      href: string
      isMenu?: false
      menuChildren?: never
      isNotification?: false
    }
)

const Link = ({
  menuChildren,
  activeIcon,
  text,
  href,
  isNotification = false,
  ...props
}: LinkProps) => {
  const is2XS = useMediaQuery("@media only screen and (max-width: 340px)")
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  if (is2XS) {
    text = ""
  }

  useEffect(() => {
    const event = new Event(EXPLORE_MENU_OPENED_EVENT_NAME)
    window.dispatchEvent(event)
  }, [open])

  if (props.isMenu) {
    if (props.arrow === undefined) {
      props.arrow = true
    }
    return (
      <div
        className={clsx(
          "rounded-xl !no-underline hover:outline hover:outline-primary-100",
          {
            [classes.isNotification]: isNotification,
          }
        )}
      >
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <div
              className={clsx(
                "relative flex cursor-pointer items-center justify-center gap-1 rounded-xl px-1 py-0.5 text-onSurface-500",
                { "text-primary-600 bg-primary-50": open }
              )}
            >
              {open && (
                <motion.div
                  initial={{ x: 10, opacity: 0 }}
                  animate={{ x: open ? 0 : 10, opacity: open ? 1 : 0 }}
                  transition={{ duration: 0.3, ease: "easeIn" }}
                  className={clsx("mt-0.25 size-2.5", {
                    "scale-0 opacity-0": !open,
                  })}
                >
                  {activeIcon}
                </motion.div>
              )}
              {text && (
                <p
                  className={clsx(
                    "flex justify-center text-[15px] font-semibold !leading-5 text-onSurface-500",
                    {
                      "text-primary-600": open,
                    }
                  )}
                >
                  <span>{text}</span>
                </p>
              )}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuGroup>{menuChildren}</DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    )
  }

  if (!href) throw new Error("Link supplied without href")
  return (
    <NavLink
      style={{
        textDecoration: "none",
        border: 0,
      }}
      to={href}
    >
      {({ isActive }) => (
        <div
          className={clsx(
            "relative flex h-3.5 items-center justify-center gap-1 rounded-xl p-0.75 text-onSurface-500 hover:outline hover:outline-primary-100",
            {
              "text-primary-600": isActive,
            }
          )}
          data-active={isActive ? "true" : "false"}
        >
          {isActive && (
            <motion.div
              initial={{ x: 10, opacity: 0 }}
              animate={{ x: isActive ? 0 : 10, opacity: isActive ? 1 : 0 }}
              transition={{ duration: 0.3, ease: "easeIn" }}
              className={clsx("size-2.5", {
                "scale-0 opacity-0": !isActive,
              })}
            >
              {activeIcon}
            </motion.div>
          )}

          {text && (
            <p
              className={clsx(
                "text-[15px] font-semibold leading-5 text-onSurface-500",
                {
                  "text-primary-600": isActive,
                }
              )}
            >
              {text}
            </p>
          )}
        </div>
      )}
    </NavLink>
  )
}

export default Link
