import {
  Avatar,
  Button,
  Container,
  DropdownMenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import BookOpen from "assets/navigation/BookOpen.svg"
import HomeLine from "assets/navigation/HomeLine"
import HomeSmile from "assets/navigation/HomeSmile.svg"
import clsx from "clsx"
import ProfileNotCompletedBanner from "components/auth/ProfileNotCompletedBanner"
import {
  Journal,
  MediaVideoList,
  MicrophoneSpeaking,
  Search,
  Shop,
  ViewGrid,
} from "iconoir-react"
import React, { useRef, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { Link as ReactRouterLink } from "react-router-dom"
import { clearAuthInfo, getAuthInfo } from "utils/auth"
import { USER_TYPE } from "utils/constants"
import { buildUserName, getPlatformURL, signInAndContinue } from "utils/helpers"
import { routes } from "utils/routes"

import BackButton, { BackButtonProps } from "./BackButton"
import Link, { NavBackground } from "./Link"
import { LinkWithQuery } from "./LinkWithQuery"
import ProfileMenuButton from "./ProfileMenuButton"
import Sidebar from "./Sidebar"

const NAVBAR_HEIGHT = 62
const useStyles = createUseStyles(theme => ({
  root: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.navbar,
  },

  nav: {
    height: NAVBAR_HEIGHT,
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    backgroundColor: "white",
    position: "relative",
    zIndex: theme.zIndex.navbar,
    transition: "box-shadow 225ms ease-in-out",
  },

  gutterBottom: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(8),
    },
  },
  containerRoot: {
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  enableShadow: {
    boxShadow: "1px 1px 5px 0px #0000001A",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  divider: {
    height: "100%",
    width: 2,
    background: theme.colors.onSurface[200],
  },
  textFontFamily: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 600,
    color: theme.colors.onSurface[500],
  },
  notificationContainer: {
    padding: "6px 12px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  notificationPopup: {
    flexGrow: 1,
  },
}))

export type NavbarProps = {
  slotEnd?: React.ReactElement
  className?: string
  gutterBottom?: boolean
  hideBackButton?: boolean
  backButtonProps?: BackButtonProps
  showAuth?: boolean
  hideBanner?: boolean
}

const moreLinks = [
  {
    name: "Store",
    link: getPlatformURL("suraasa", "/store"),
    icon: Shop,
  },
  {
    name: "Masterclasses",
    link: getPlatformURL("suraasa", "/masterclasses"),
    icon: MediaVideoList,
  },
  {
    name: "Blogs",
    link: getPlatformURL("suraasa", "/blog"),
    icon: Journal,
  },
  {
    name: "Podcasts",
    link: "https://podcasters.spotify.com/pod/show/suraasa",
    icon: MicrophoneSpeaking,
  },
]

const Navbar = ({
  gutterBottom = true,
  hideBackButton = false,
  hideBanner = false,
  backButtonProps,
}: NavbarProps) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const containerRef = useRef(null)

  const authInfo = getAuthInfo()
  const userName = authInfo ? buildUserName(authInfo.user) : ""

  const [showSidebar, setShowSidebar] = useState(false)
  const handleLogout = () => {
    clearAuthInfo()
    const url = new URL(`${import.meta.env.VITE_SSO_URL}/logout`)
    url.searchParams.append("origin", `${window.location.origin}`)
    url.searchParams.append("platform", USER_TYPE)
    window.location.href = url.href
  }

  if (!authInfo) {
    return (
      <>
        <nav
          id="topNavbar"
          style={{
            boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
            position: "sticky",
            top: 0,
            background: "white",
            borderBottom: `1px solid ${theme.colors.surface[200]}`,
            zIndex: theme.zIndex.navbar,
            transition: "top 0.3s",
          }}
        >
          <Container>
            <div className="flex items-center justify-between py-1.25">
              <ReactRouterLink to={routes.home}>
                <img
                  src="https://assets.suraasa.com/logos/v2/mini.webp"
                  alt="suraasa logo"
                  height={35}
                  width={35}
                  className="block md:hidden"
                />
                <img
                  src="https://assets.suraasa.com/logos/v2/full-dark.webp"
                  alt="suraasa logo"
                  width={94}
                  className="hidden md:block"
                />
              </ReactRouterLink>
              <LinkWithQuery to={signInAndContinue(false)}>
                <Button variant="text">Sign In</Button>
              </LinkWithQuery>
            </div>
          </Container>
        </nav>
        {!hideBackButton && (
          <div className="my-2">
            <Container className={classes.containerRoot}>
              <BackButton {...backButtonProps} />
            </Container>
          </div>
        )}
        <div className={clsx({ [classes.gutterBottom]: gutterBottom })} />
      </>
    )
  }

  if (!mdUp && authInfo) {
    return (
      <>
        <nav
          className="flex items-center justify-between px-2 py-1"
          id="topNavbar"
          style={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: theme.zIndex.navbar,
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
            transition: "top 0.3s",
          }}
        >
          <div className="flex shrink-0 items-center">
            <button onClick={() => setShowSidebar(true)}>
              <Avatar
                name={userName}
                className="size-4 border border-onSurface-200"
                src={authInfo.user?.photo}
              />
            </button>
            <Sidebar
              handleClose={() => {
                setShowSidebar(false)
              }}
              handleLogout={handleLogout}
              open={showSidebar}
            />
          </div>
        </nav>
        {!hideBanner && <ProfileNotCompletedBanner />}
        {!hideBackButton && (
          <div className="my-2">
            <Container className={classes.containerRoot}>
              <BackButton {...backButtonProps} />
            </Container>
          </div>
        )}
        <div className={clsx({ [classes.gutterBottom]: gutterBottom })} />
      </>
    )
  }

  return (
    <>
      <nav
        id="topNavbar"
        className={clsx(
          "top-0 z-navbar border-b border-surface-200 bg-white py-0.25"
        )}
        style={{
          boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Container>
          <div className="flex items-center justify-between">
            {mdUp ? (
              <>
                <ReactRouterLink to={routes.home}>
                  <img
                    src="https://assets.suraasa.com/logos/v2/mini.webp"
                    alt="suraasa logo"
                    height={35}
                    width={35}
                    className="block md:hidden"
                  />
                  <img
                    src="https://assets.suraasa.com/logos/v2/full-dark.webp"
                    alt="suraasa logo"
                    width={93.26}
                    className="hidden md:block"
                  />
                </ReactRouterLink>
                <div
                  className="relative flex items-center justify-center gap-2"
                  ref={containerRef}
                >
                  <NavBackground containerRef={containerRef} />
                  <Link
                    activeIcon={<HomeLine height={22} />}
                    href={getPlatformURL("learn", "/home")}
                    text="Home"
                  />
                  <Link
                    activeIcon={<img src={BookOpen} alt="" />}
                    href={getPlatformURL("learn", "/learning")}
                    text="Learning"
                  />
                  <Link
                    activeIcon={<ViewGrid height={18} width={18} />}
                    menuChildren={
                      <>
                        {moreLinks.map((x, i) => (
                          <DropdownMenuItem key={i} startAdornment={<x.icon />}>
                            <ReactRouterLink to={x.link}>
                              <Typography>{x.name}</Typography>
                            </ReactRouterLink>
                          </DropdownMenuItem>
                        ))}
                      </>
                    }
                    text="Explore"
                    isMenu
                  />
                  <Link
                    activeIcon={<img src={BookOpen} alt="" />}
                    href={getPlatformURL("learn", "/ai-tools")}
                    text="AI Tools"
                  />

                  <Link
                    activeIcon={<Search height={20} width={20} />}
                    href={routes.home}
                    text="Jobs"
                  />
                </div>
                <ProfileMenuButton handleLogout={handleLogout} />
              </>
            ) : (
              <Link
                activeIcon={<img src={HomeSmile} alt="" />}
                href={routes.home}
                icon={<HomeLine />}
                text="Home"
              />
            )}
          </div>
        </Container>
      </nav>
      {!hideBanner && <ProfileNotCompletedBanner />}
      {!hideBackButton && (
        <div className="my-2">
          <Container className={classes.containerRoot}>
            <BackButton {...backButtonProps} />
          </Container>
        </div>
      )}
      <div className={clsx({ [classes.gutterBottom]: gutterBottom })} />
    </>
  )
}

export default Navbar
