import { Select, Typography } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Country, State } from "api/resources/global/types"
import {
  Form,
  ProfileCompletionContext,
} from "components/ProfileCompletion/utils"
import React, { useContext, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

const WhereDoYouWantToTeach = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<Form>()
  const { countries } = useContext(ProfileCompletionContext)

  /**
   * undefined --> options are being fetched(loading...)
   * null --> default || api call failed || options don't exist
   */
  const [states, setStates] = useState<State[] | null | undefined>(null)
  const country = watch("countryId")

  useEffect(() => {
    if (!country) {
      setStates(null)
    }
  }, [country])

  const fetchStates = async (countryId: Country["uuid"]) => {
    setStates(undefined)
    setValue("stateId", null)
    const res = await api.global.listStates({
      urlParams: {
        countryId,
      },
      params: {
        page: -1,
      },
    })
    if (res.isSuccessful) {
      setStates(res.data)
    } else {
      setStates(null)
    }
  }

  return (
    <div>
      <Typography className="mb-2" variant="strong">
        Where do you want to teach?
      </Typography>
      <div className="grid-cols-6 md:grid">
        <div className="flex flex-col gap-3 md:col-span-5 md:flex-row">
          <Controller
            control={control}
            name="countryId"
            render={({ field: { onChange, value } }) => (
              <Select
                error={Boolean(errors.countryId)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                helperText={errors.countryId?.message}
                label="country"
                options={countries}
                value={value ? countries.find(c => c.uuid === value) : null}
                fullWidth
                isClearable
                required={false}
                onChange={val => {
                  if (val) {
                    fetchStates(val.uuid)
                  }
                  onChange(val ? val.uuid : "")
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />

          <Controller
            control={control}
            name="stateId"
            render={({ field: { onChange, value } }) => (
              <Select
                error={Boolean(errors.stateId)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                helperText={errors.stateId?.message}
                isLoading={states === undefined}
                label="State/Region/Province"
                options={states ?? []}
                placeholder="Ex: Dubai"
                value={
                  value ? states && states.find(c => c.uuid === value) : null
                }
                fullWidth
                isClearable
                required={false}
                onChange={val => onChange(val ? val.uuid : "")}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default WhereDoYouWantToTeach
