import {
  Button,
  CircularProgress,
  Pagination,
  Tag,
  TagColors,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { JobOfferStatus, JobOfferType } from "api/resources/jobs/types"
import clsx from "clsx"
import NoDataCard from "components/shared/NoDataCard"
import SchoolLogo from "components/shared/SchoolLogo"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },
  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableGrid: {
    display: "grid",
    gridTemplateColumns: "70px 1.5fr 1fr 1fr 1fr",
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },
}))

const getTag = (
  status: JobOfferStatus
): {
  color: TagColors
  label: string
} => {
  switch (status) {
    case JobOfferStatus.ACCEPTED:
      return { color: "success", label: "Accepted" }
    case JobOfferStatus.REJECTED:
      return { color: "critical", label: "Rejected" }
    case JobOfferStatus.SENT:
      return { color: "secondary", label: "Pending" }
    default:
      return { color: "secondary", label: "" }
  }
}

const JobOffersTab = ({
  updateNotifications,
}: {
  updateNotifications: () => void
}) => {
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [jobOffers, setJobOffers] = useState<JobOfferType[]>([])

  const classes = useStyles()

  useEffect(() => {
    const fetchData = async () => {
      const jobOfferRes = await api.jobs.jobOffers.list({ params: { page } })

      if (jobOfferRes.isSuccessful) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { data, total } = jobOfferRes.data
        setTotal(total)
        setJobOffers(data)
      }
      setLoading(false)
    }
    fetchData()
  }, [page])

  useEffect(() => {
    const timer = window.setTimeout(async () => {
      const jobs = jobOffers
        .filter(job => job.isViewed === false)
        .map(job => job.id)

      if (jobs.length === 0) return

      const markAsSeenRes = await api.jobs.jobOffers.markAsSeen({
        data: { jobOffers: jobs },
      })

      if (markAsSeenRes.isSuccessful) updateNotifications()
      else console.error(markAsSeenRes.errors)
    }, 5000)

    return () => {
      window.clearTimeout(timer)
    }
  }, [jobOffers, updateNotifications])

  return loading ? (
    <div className="mt-5 flex items-center justify-center">
      <CircularProgress />
    </div>
  ) : (
    <>
      <div className={clsx(classes.root, "my-3")}>
        {jobOffers.length > 0 ? (
          <div className={classes.container}>
            <div className={clsx(classes.tableGrid, "py-2")}>
              <div />
              <Typography variant="strongSmallBody">Name</Typography>

              <Typography variant="strongSmallBody">
                Offer Received Date
              </Typography>

              <Typography textAlign="center" variant="strongSmallBody">
                Status
              </Typography>
            </div>

            {jobOffers.map((item, index) => {
              const tag = getTag(item.status)
              return (
                <div
                  className={clsx(classes.tableGrid, classes.tableItem, "py-2")}
                  key={index}
                >
                  <div className="mt-0.5 pl-3">
                    <SchoolLogo
                      className={classes.image}
                      src={item.school.logo}
                    />
                  </div>

                  <div>
                    <div className="flex items-center gap-0.5">
                      <Button
                        className="-ml-0.75"
                        component={Link}
                        to={`${routes.schoolProfile.replace(
                          ":slug",
                          item.school.slug
                        )}?jobSlug=${item.job.slug}`}
                        variant="link"
                      >
                        <Typography
                          color="interactive.600"
                          display="inline"
                          variant="smallBody"
                        >
                          {item.school.name}
                        </Typography>
                      </Button>
                    </div>
                    {item.school.country && item.school.state && (
                      <Typography
                        className={clsx(classes.textSmall, "mb-0.5")}
                        color="onSurface.500"
                      >
                        {item.school.state.name}, {item.school.country.name}
                      </Typography>
                    )}

                    <Typography variant="smallBody">
                      {item.job.position}
                    </Typography>

                    <Typography variant="smallBody">
                      {item.job.subject.name}
                    </Typography>
                  </div>

                  <div className="flex flex-col justify-center">
                    <Typography variant="smallBody">
                      {format(new Date(item.dateCreated), "do MMM yyyy")}
                    </Typography>
                  </div>

                  <div className="flex flex-col items-center justify-center">
                    <Tag color={tag.color} label={tag.label} />
                  </div>

                  <div className="mr-2 flex flex-col items-end justify-center">
                    <Button
                      component={Link}
                      to={routes.viewOffer.replace(
                        ":jobOfferId",
                        item.id.toString()
                      )}
                      variant="text"
                    >
                      View Offer Letter
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <NoDataCard message="You don't have any job offers yet." />
        )}
      </div>
      {total > 10 && (
        <Pagination page={page} total={total} onChange={setPage} />
      )}
    </>
  )
}
export default JobOffersTab
