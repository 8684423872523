// import { JobsInUsaCard } from "@suraasa/utils"
import api from "api"
import { Enrollment } from "api/resources/enrollments/types"
import { AcceptedJobOverview, StepsOverview } from "api/resources/jobs/types"
import { Profile } from "api/resources/profile/types"
import { usePostHog } from "posthog-js/react"
import React, { createContext, useEffect, useState } from "react"
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom"
import { getAuthInfo, saveAuthInfo } from "utils/auth"
import { isLocalhost } from "utils/constants"
import { buildUserName } from "utils/helpers"
import { routes } from "utils/routes"

export type Context = {
  overview: {
    set: React.Dispatch<React.SetStateAction<AcceptedJobOverview>>
  } & AcceptedJobOverview
  stepsOverview: StepsOverview & {
    loading: boolean
    _extra?: { autoRedirect: boolean }
  }
  setStepsOverview: React.Dispatch<
    React.SetStateAction<StepsOverview & { loading: boolean }>
  >
  enrollments: Enrollment[]
  profile: Profile
}

const defaultValues = {
  overview: {
    jobsLockedTill: "",
    set: () => {},
  },
  enrollments: [],

  stepsOverview: {
    marketingFormDataExists: false,
    profileDataExists: false,
    resumeExists: false,
    marketingSignupComplete: false,
    qualificationDataExists: false,
    loading: true,
    _extra: {
      autoRedirect: true,
    },
  },
  setStepsOverview: () => {},
  notifications: { data: null, unreadCount: 0, totalCount: 0 },
  profile: {} as Profile,
}

export const GlobalContext = createContext<Context>(defaultValues)

const GlobalState = () => {
  const [overview, setOverview] = useState<AcceptedJobOverview>({
    jobsLockedTill: "",
  })
  const [profile, setProfile] = useState<Profile>({} as Profile)

  const [searchParams] = useSearchParams()

  const [enrollments, setEnrollments] = useState<Enrollment[]>([])

  const [stepsOverview, setStepsOverview] = useState<Context["stepsOverview"]>(
    defaultValues.stepsOverview
  )

  const auth = getAuthInfo()

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.jobs.stepsOverview()
      const profileRes = await api.profile.retrieve()

      if (profileRes.isSuccessful && res.isSuccessful) {
        setStepsOverview(prevState => ({
          ...prevState,
          ...res.data,
          resumeExists: !!profileRes.data.resume,
          loading: false,
        }))
        setProfile(profileRes.data)
      }

      if (profileRes.isSuccessful) {
        if (auth)
          saveAuthInfo({
            ...auth,
            user: { ...auth.user, photo: profileRes.data.picture || "" },
          })
      }
    }

    if (auth) fetchData()
    else setStepsOverview(prevState => ({ ...prevState, loading: false }))
  }, [])

  useEffect(() => {
    const listEnrollments = async () => {
      const res = await api.enrollments.list()
      if (res.isSuccessful) {
        setEnrollments(res.data)
      }
    }

    if (auth) {
      listEnrollments()
    }
  }, [])

  const posthog = usePostHog()
  const location = useLocation()

  useEffect(() => {
    posthog.capture("$pageview")
    // return () => {
    // posthog.capture("$pageleave")
    // console.log(`> Capturing page leave: ${location.pathname}`)
    // }
  }, [location])

  useEffect(() => {
    if (auth) {
      posthog?.identify(auth.user.uuid, {
        email: auth.user.email,
        name: buildUserName(auth.user),
      })
    }
  }, [])

  useEffect(() => {
    if (isLocalhost) {
      posthog.debug()
    }
  }, [])

  if (!auth && window.location.pathname === "/") {
    return (
      <Navigate to={routes.explore + "?" + searchParams.toString()} replace />
    )
  }
  if (auth && window.location.pathname === "/") {
    return <Navigate to={routes.home + "?" + searchParams.toString()} replace />
  }

  return (
    <GlobalContext.Provider
      value={{
        overview: { ...overview, set: setOverview },
        stepsOverview,
        setStepsOverview,
        enrollments,
        profile,
      }}
    >
      <Outlet />
      {/* <CheckEnrollment items={enrollmentsForUSAJobs}>
        <JobsInUsaCard />
      </CheckEnrollment> */}
    </GlobalContext.Provider>
  )
}

export default GlobalState
