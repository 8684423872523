import {
  Button,
  ButtonComponentProps,
  Chip,
  Typography,
} from "@suraasa/placebo-ui-legacy"

type Props = {
  title: string
  answer: string | string[]
  actionProps: Partial<ButtonComponentProps<"button">>
}

const AnsweredQuestion = ({ title, answer, actionProps }: Props) => {
  const isArray = Array.isArray(answer)

  return (
    <div className="flex flex-wrap items-center justify-between gap-1">
      <div className="md:flex-1">
        <Typography display={isArray ? "block" : "inline"} variant="body">
          {title}
        </Typography>

        {!isArray && (
          <Typography
            className="ml-2"
            display={isArray ? "block" : "inline"}
            variant="strong"
          >
            {answer}
          </Typography>
        )}

        {isArray && answer.length > 0 && (
          <div className="mt-1 flex flex-wrap gap-1">
            {answer.map(item => (
              <Chip key={item} label={item} variant="outlined" />
            ))}
          </div>
        )}
      </div>

      <Button label="Change" variant="text" {...actionProps} />
    </div>
  )
}

export default AnsweredQuestion
