import { ActiveJobOverview } from "api/resources/jobs/types"
import { createContext } from "react"

export const TabContext = createContext<{
  overview: ActiveJobOverview
  setOverview: React.Dispatch<React.SetStateAction<ActiveJobOverview>>
}>({
  overview: {
    newInvites: 0,
    newOffers: 0,
    totalActiveApplications: 0,
    totalApplied: 0,
    totalInvites: 0,
    totalJobOffers: 0,
  },
  setOverview: () => {},
})
