import { Divider } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"
import { tabsProfile } from "utils/constants"

import Amenities from "./Amenities"
import Perks from "./Perks"

const useStyles = createUseStyles(theme => ({
  container: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.colors.surface[200]}`,
  },
}))

function PerksAndAmenities() {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        "px-2 pt-3.5 sm:px-5 sm:py-4 mt-3 mb-6",
        classes.container
      )}
      id={tabsProfile.perksAndAmenities}
    >
      <Amenities />
      <Divider className="my-5" color="onSurface.200" />
      <Perks />
    </div>
  )
}

export default PerksAndAmenities
