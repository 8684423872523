import {
  Button,
  Divider,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { WorkExperienceType } from "api/resources/profile/types"
import { STEPS } from "components/ProfileCompletion/constants"
import Heading from "components/ProfileCompletion/Heading"
import { ProfileCompletionContext } from "components/ProfileCompletion/utils"
import LoadingOverlay from "components/shared/LoadingOverlay"
import SharedDialog from "components/SharedDialog"
import { format } from "date-fns"
import { GlobalContext } from "GlobalState"
import { ArrowRight, EditPencil, Plus, Trash } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import useArray from "utils/hooks/useArray"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"

import EditDialog from "./EditDialog"

const WorkExperienceItem = ({
  title,
  endDate,
  startDate,
  organisationName,
  onEdit,
  onDelete,
}: WorkExperienceType & { onEdit: () => void; onDelete: () => void }) => (
  <div>
    <div className="flex items-center justify-between">
      <Typography variant="strong">{title}</Typography>
      <div className="-mt-1.25 flex">
        <IconButton color="primary" size="sm" onClick={onEdit}>
          <EditPencil />
        </IconButton>
        <IconButton color="critical" size="sm" onClick={onDelete}>
          <Trash />
        </IconButton>
      </div>
    </div>
    {/* <Typography variant="body">{qualificationLevel.name}</Typography> */}
    <Typography variant="body">{organisationName}</Typography>
    {startDate && (
      <Typography variant="smallBody">
        {[
          format(new Date(startDate), "MMMM yyyy"),
          endDate ? format(new Date(endDate), "MMMM yyyy") : "Present",
        ].join(" - ")}
      </Typography>
    )}
    <Divider className="mb-3 mt-2.5" color="onSurface.300" weight="light" />
  </div>
)

const WorkExperiences = () => {
  const [loading, setLoading] = useState(false)

  const { setStep } = useContext(ProfileCompletionContext)
  const { setStepsOverview } = useContext(GlobalContext)

  const [openDialog, toggleDialog] = useToggle(false)
  const [openRemoveDialog, toggleRemoveDialog] = useToggle(false)
  const [removeLoading, toggleRemoveLoading] = useToggle(false)

  const workExperiences = useArray<WorkExperienceType>([])

  const [workExperienceToEdit, setWorkExperienceToEdit] = useState<
    WorkExperienceType["id"] | null
  >(null)
  const [workExperienceToDelete, setWorkExperienceToDelete] = useState<
    WorkExperienceType["id"] | null
  >(null)

  useEffect(() => {
    // Clear workExperienceToEdit when dialog is closed
    if (workExperienceToEdit && !openDialog) {
      setWorkExperienceToEdit(null)
    }
  }, [workExperienceToEdit, openDialog])

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const res = await api.profile.workExperiences.list({
        urlParams: {},
      })
      if (res.isSuccessful) {
        workExperiences.set(res.data)
      } else {
        workExperiences.set([])
      }
      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRemove = async () => {
    if (!workExperienceToDelete) return

    toggleRemoveLoading(true)
    const res = await api.profile.workExperiences.delete({
      urlParams: { id: workExperienceToDelete },
    })

    if (res.isSuccessful) {
      workExperiences.removeByKey(workExperienceToDelete)
      toast.success("Removed successfully.")
      toggleRemoveDialog()
      setWorkExperienceToDelete(null)
    } else if (res.errors.message) {
      toast.error(res.errors.message)
    }

    toggleRemoveLoading(false)
  }

  return (
    <>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <>
          <div className="flex flex-col" style={{ height: "100%" }}>
            <Heading
              subtitle="Let’s add work experiences in your profile, it will help schools when they look at your profile."
              title="Work Experience"
            />
            {workExperiences.array.length === 0 ? (
              <div className="flex items-center gap-1">
                <Button onClick={() => toggleDialog()}>
                  Add Work Experience
                </Button>
                <Button
                  onClick={() => {
                    setStep(STEPS.AcademicDetails)
                  }}
                  variant="text"
                >
                  Skip for now
                </Button>
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="col-span-1">
                      {workExperiences.array.map(item => (
                        <WorkExperienceItem
                          {...item}
                          key={item.id}
                          onDelete={() => {
                            setWorkExperienceToDelete(item.id)
                            toggleRemoveDialog()
                          }}
                          onEdit={() => {
                            setWorkExperienceToEdit(item.id)
                            toggleDialog()
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <Button
                    nudge="left"
                    startAdornment={<Plus />}
                    variant="text"
                    onClick={() => toggleDialog()}
                  >
                    Add Another Work Experience
                  </Button>
                </div>
                <Button
                  className="mt-auto self-center md:self-start"
                  endAdornment={<ArrowRight />}
                  onClick={() => {
                    setStep(STEPS.AcademicDetails)
                  }}
                >
                  Continue
                </Button>
              </div>
            )}
          </div>

          {openDialog && (
            <EditDialog
              id={workExperienceToEdit}
              open={openDialog}
              workExperiences={{
                data: workExperiences.array,
                update: workExperiences.updateByKey,
                remove: workExperiences.removeByKey,
                add: workExperiences.unshift,
              }}
              toggle={toggleDialog}
            />
          )}

          <SharedDialog
            handleClose={toggleRemoveDialog}
            loading={removeLoading}
            open={openRemoveDialog}
            title="Remove Work Experience"
            isDestructive
            keepScrollLocked
            onConfirm={handleRemove}
          >
            Are you sure you want to remove{" "}
            <b>
              {
                workExperiences.array.find(
                  item => item.id === workExperienceToDelete
                )?.title
              }
            </b>{" "}
            from your profile?
          </SharedDialog>
        </>
      )}
    </>
  )
}

export default WorkExperiences
