import { Button, Typography } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Job, StepsOverview } from "api/resources/jobs/types"
import clsx from "clsx"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { ArrowRight } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { getAuthInfo } from "utils/auth"
import { signInAndContinue } from "utils/helpers"

import { STEPS } from "../constants"
import { ProfileCompletionContext } from "../utils"

const useStyles = createUseStyles({
  root: {
    "& > *": {
      textAlign: "center",
    },
    height: "100%",
  },
})

const JobOverview = ({
  stepsOverview: {
    profileDataExists,
    marketingFormDataExists,
    qualificationDataExists,
    resumeExists,
  },
  jobId,
}: {
  stepsOverview: StepsOverview
  jobId: number
}) => {
  const { setStep, toggle } = useContext(ProfileCompletionContext)

  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [jobData, setJobData] = useState<Job>()

  const handleSetStep = () => {
    if (!isLoggedIn) {
      // This will force authentication and bring the user back to the same page.
      signInAndContinue()
      return
    }

    if (
      profileDataExists &&
      marketingFormDataExists &&
      qualificationDataExists &&
      resumeExists
    ) {
      setStep(STEPS.Congratulations)
      return
    }

    if (!profileDataExists) {
      setStep(STEPS.PersonalDetails)
      return
    }
    if (!marketingFormDataExists) {
      setStep(STEPS.MarketingData)
      return
    }
    if (!qualificationDataExists) {
      setStep(STEPS.AcademicDetails)
      return
    }
    if (!resumeExists) {
      setStep(STEPS.UploadResume)
      return
    }

    if (toggle) toggle()
  }

  useEffect(() => {
    const getJobOverview = async () => {
      setLoading(true)
      const res = await api.jobs.retrieve({ urlParams: { jobId } })
      if (res.isSuccessful) {
        setJobData(res.data)
      } else {
        setError(res.errors.message || "Something went wrong")
      }
      setLoading(false)
    }

    if (jobId) getJobOverview()
  }, [jobId, toggle])

  if (loading) {
    return <LoadingOverlay />
  }

  const isLoggedIn = Boolean(getAuthInfo())

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center",
        classes.root
      )}
    >
      {!error && jobData && (
        <Typography color="primary.800" variant="title1">
          Applying for {jobData.position}, {jobData.subject.name} at{" "}
          {jobData.school.name}
        </Typography>
      )}

      <Typography className="mt-5" color="onSurface.700" variant="title2">
        Let’s fill in your application!
      </Typography>
      <Typography className="mt-3" variant="largeBody">
        You need to answer few questions only the first time you apply for a job
        through Suraasa
      </Typography>
      <div className="mt-12 flex flex-col items-center gap-2">
        <Button endAdornment={<ArrowRight />} onClick={handleSetStep}>
          {isLoggedIn ? "Start My Application" : "Sign In & Apply"}
        </Button>
      </div>
    </div>
  )
}

export default JobOverview
