import {
  Button,
  CircularProgress,
  Container,
  Tag,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { JobOfferDetailsType, JobOfferStatus } from "api/resources/jobs/types"
import JobPostCard from "components/jobs/JobPostCard"
import JobOfferDetails from "components/jobs/OfferLetter/JobOfferDetails"
import AcceptedOfferMessage from "components/jobs/ViewOffer/AcceptedOfferMessage"
import AcceptOfferDialog from "components/jobs/ViewOffer/AcceptOfferDialog"
import OTPDialog from "components/jobs/ViewOffer/OTPDialog"
import RejectOfferDialog from "components/jobs/ViewOffer/RejectOfferDialog"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import Navbar from "components/shared/Navbar"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"

const ViewOffer = () => {
  const { jobOfferId } = useParams()

  const navigate = useNavigate()
  const [rejectDialog, toggleRejectDialog] = useToggle(false)
  const [acceptDialog, toggleAcceptDialog] = useToggle(false)
  const [otpDialog, toggleOtpDialog] = useToggle(false)

  const [offerStatus, setOfferStatus] = useState<JobOfferStatus>()

  useEffect(() => {
    if (otpDialog) {
      toggleAcceptDialog(false)
      toggleRejectDialog(false)
    }
  }, [otpDialog, toggleAcceptDialog, toggleRejectDialog])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  const [jobDetails, setJobDetails] = useState<JobOfferDetailsType>()

  useEffect(() => {
    const getData = async () => {
      if (!jobOfferId) return
      setLoading(true)

      const res = await api.jobs.jobOffers.retrieve({
        urlParams: { jobOfferId },
      })

      if (res.isSuccessful) {
        setJobDetails(res.data)
      } else {
        if (res.statusCode === 404) {
          navigate(routes.acceptedOffer)
        }
        if (res.errors.message) setError(res.errors.message)
        else setError("An unknown error occurred")
      }
      setLoading(false)
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOfferId])

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center p-3">
          <CircularProgress />
        </div>
      )
    }

    if (error) {
      return (
        <Section className="m-3">
          <SectionContent className="flex items-center justify-center p-3">
            <Typography className="title3">{error}</Typography>
          </SectionContent>
        </Section>
      )
    }

    if (jobDetails) {
      if (jobDetails.status === JobOfferStatus.ACCEPTED)
        return <AcceptedOfferMessage details={jobDetails} />
      return (
        <>
          <div className="flex items-center justify-between mb-3">
            {jobDetails.status === JobOfferStatus.REJECTED && (
              <Tag color="critical" label="Rejected" />
            )}
          </div>
          <JobPostCard job={jobDetails.job} jobOfferDetails={jobDetails} />
          <JobOfferDetails details={jobDetails}>
            {jobDetails.status === JobOfferStatus.SENT && (
              <div className="mt-4 flex gap-2 flex-wrap">
                <Button onClick={() => toggleAcceptDialog()}>
                  Accept Offer
                </Button>
                <Button
                  color="critical"
                  variant="outlined"
                  onClick={() => toggleRejectDialog()}
                >
                  Reject Offer
                </Button>
              </div>
            )}
          </JobOfferDetails>
          <AcceptOfferDialog
            handleAccept={() => {
              setOfferStatus(JobOfferStatus.ACCEPTED)
              toggleOtpDialog()
            }}
            open={acceptDialog}
            toggle={toggleAcceptDialog}
          />
          <RejectOfferDialog
            handleReject={() => {
              setOfferStatus(JobOfferStatus.REJECTED)
              toggleOtpDialog()
            }}
            open={rejectDialog}
            toggle={toggleRejectDialog}
          />
          {offerStatus &&
            (offerStatus === JobOfferStatus.ACCEPTED ||
              offerStatus === JobOfferStatus.REJECTED) && (
              <OTPDialog
                afterSubmit={() =>
                  setJobDetails(i => ({
                    ...(i as JobOfferDetailsType),
                    status: JobOfferStatus.ACCEPTED,
                  }))
                }
                open={otpDialog}
                school={jobDetails.job.school.name}
                status={offerStatus}
                toggle={toggleOtpDialog}
              />
            )}
        </>
      )
    }
  }

  return (
    <>
      <Navbar gutterBottom={false} />
      <Container>{renderContent()}</Container>
    </>
  )
}

export default ViewOffer
