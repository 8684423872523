import { AuthData } from "api/resources/users/types"
import { transformKeys } from "api/utils"
import camelCase from "lodash/camelCase"
import { BROWSER_STORAGE_KEYS } from "utils/constants"

export function saveAuthInfo(authInfo: AuthData) {
  localStorage.setItem(BROWSER_STORAGE_KEYS.auth, JSON.stringify(authInfo))
}

export function getAuthInfo() {
  const authInfo = localStorage.getItem("auth")

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  return authInfo
}

export function clearAuthInfo() {
  const { auth } = BROWSER_STORAGE_KEYS
  localStorage.removeItem(auth)
}

export const generateLoginURL = ({
  redirectUrl,
  next,
  ...rest
}: { redirectUrl: string; next: string } & { [key: string]: string }) => {
  const SSO_URL = import.meta.env.VITE_SSO_URL

  let redirectTo = encodeURIComponent(redirectUrl)
  if (next) redirectTo = `${redirectTo}?next=${encodeURIComponent(next)}`

  const restParams = Object.keys(rest)
    .map(key => `${key}=${rest[key]}`)
    .join("&")
  const url = `${SSO_URL}/?redirect-url=${redirectTo}&${restParams}`

  return url
}
