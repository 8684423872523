import {
  Button,
  Divider,
  Select,
  TextField,
  Theme,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Country, State } from "api/resources/global/types"
import { Gender } from "api/resources/profile/types"
import { PhoneNumber } from "api/resources/users/types"
import { STEPS } from "components/ProfileCompletion/constants"
import Heading from "components/ProfileCompletion/Heading"
import { ProfileCompletionContext } from "components/ProfileCompletion/utils"
import { ArrowRight, Check } from "iconoir-react"
import { useCallback, useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTheme } from "react-jss"
import countryCodes from "utils/countryCodes"
import { getPhoneNumber, handleErrors } from "utils/helpers"
import isMobilePhone from "validator/es/lib/isMobilePhone"

import PhoneNumberVerificationDialog from "./PhoneNumberVerificationDialog"

const genderList: {
  label: string
  value: Gender
}[] = [
  { label: "Male", value: Gender.MALE },
  { label: "Female", value: Gender.FEMALE },
  { label: "Prefer not to say", value: Gender.PREFER_NOT_TO_SAY },
]

type Form = {
  countryCode: string
  phoneNumber: string
  gender: Gender | null
  nationalityId: string | null
  countryId: string | null
  stateId: string | null
  dateOfBirth: string | null
}

const PersonalDetails = () => {
  const { setStep, countries } = useContext(ProfileCompletionContext)
  const theme = useTheme<Theme>()
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Form>()

  /**
   * undefined --> options are being fetched(loading...)
   * null --> default || api call failed || options don't exist
   */
  const [states, setStates] = useState<State[] | null | undefined>(null)
  const country = watch("countryId")

  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false)

  const fetchStates = useCallback(
    async (countryId: Country["uuid"]) => {
      setStates(undefined)
      setValue("stateId", null)
      const res = await api.global.listStates({
        urlParams: {
          countryId,
        },
        params: {
          page: -1,
        },
      })
      if (res.isSuccessful) {
        setStates(res.data)
      } else {
        setStates(null)
      }
    },
    [setValue]
  )

  const onSubmit = handleSubmit(async formData => {
    const res = await api.profile.update({
      data: {
        ...formData,
        phoneNumber: `${formData.countryCode}${formData.phoneNumber}`,
      },
    })

    if (res.isSuccessful) {
      setStep(STEPS.MarketingData)
    } else {
      handleErrors(setError, res.errors)
    }
  })

  useEffect(() => {
    if (!country) {
      setStates(null)
    }
  }, [country])

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.profile.retrieve()

      const solisUserRes = await api.users.retrieve()

      let phoneNumber: PhoneNumber | null = null
      if (solisUserRes.isSuccessful) {
        if (solisUserRes.data.phoneNumber) {
          phoneNumber = solisUserRes.data.phoneNumber
        }
        if (solisUserRes.data.phoneNumberVerifiedAt) {
          setIsPhoneNumberVerified(true)
        }
      }

      if (res.isSuccessful) {
        if (res.data.countryId) {
          fetchStates(res.data.countryId)
        }
        reset({
          gender: res.data.gender,
          nationalityId: res.data.nationality?.uuid,
          countryId: res.data.countryId,
          stateId: res.data.stateId,
          phoneNumber:
            phoneNumber?.number.toString() ||
            res.data.phoneNumber?.number?.toString(),
          countryCode: phoneNumber?.code ? `+${phoneNumber.code}` : undefined,
          dateOfBirth: res.data.dateOfBirth,
        })
      }
    }
    fetchData()
  }, [fetchStates, reset])

  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false)

  const verifyPhoneNumber = async () => {
    setVerificationDialogOpen(true)
  }

  const phoneNumber = watch("phoneNumber")
  const code = watch("countryCode")

  const formattedPhoneNumber = getPhoneNumber({
    code: code ? code : "",
    number: phoneNumber ? phoneNumber.toString() : "",
  })

  return (
    <div className="flex flex-col">
      <Heading
        subtitle="Please provide some information about yourself so we can personalise your application"
        title="Personal Details"
      />
      <form className="flex flex-col gap-3" onSubmit={onSubmit}>
        <div className="grid grid-cols-5 flex-col items-end gap-1.75 sm:gap-3 md:flex-row lg:grid-cols-6">
          <Controller
            control={control}
            name="countryCode"
            render={({ field: { onChange, value } }) => (
              <Select
                className="col-span-2 md:col-span-1"
                error={Boolean(errors.countryCode)}
                getOptionLabel={({ dialCode }) => dialCode}
                getOptionValue={({ dialCode }) => dialCode}
                helperText={errors.countryCode?.message}
                label="Country Code"
                options={countryCodes}
                value={
                  value
                    ? countryCodes.find(item => item.dialCode === value)
                    : null
                }
                fullWidth
                onChange={val => {
                  onChange(val?.dialCode)
                }}
                required
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          <div className="col-span-3 flex items-center gap-1.5">
            <TextField
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber?.message}
              label="Phone Number"
              placeholder="Ex: 9845XXXXXXX"
              type="tel"
              fullWidth
              {...register("phoneNumber", {
                required: { value: true, message: "Required" },
                validate: value =>
                  isMobilePhone(value.toString(), "any") ||
                  "Please enter a valid phone number",
              })}
            />
          </div>

          {isPhoneNumberVerified && (
            <div>
              <span className="mb-1.25 flex w-max items-center gap-0.25 rounded-full bg-success-500 px-1 py-0.25 text-white">
                <Typography>Verified</Typography>
                <Check color="white" />
              </span>
            </div>
          )}
        </div>

        {!isPhoneNumberVerified && (
          <Button onClick={verifyPhoneNumber}>Verify Phone Number</Button>
        )}

        <PhoneNumberVerificationDialog
          onClose={() => setVerificationDialogOpen(false)}
          open={verificationDialogOpen}
          userPhoneNumber={formattedPhoneNumber}
          onSuccess={() => {
            setIsPhoneNumberVerified(true)
          }}
        />

        {isPhoneNumberVerified && (
          <>
            <div className="flex flex-col gap-3 md:col-span-5 md:flex-row">
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <Select
                    error={Boolean(errors.gender)}
                    getOptionLabel={item => item.label}
                    getOptionValue={item => item.value.toString()}
                    helperText={errors.gender?.message}
                    inputLabelProps={{ required: true }}
                    isSearchable={false}
                    label="Gender"
                    options={genderList}
                    value={
                      value
                        ? genderList.find(item => item.value === value)
                        : null
                    }
                    fullWidth
                    onChange={val => {
                      onChange(val?.value)
                    }}
                    required
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
              <TextField
                error={Boolean(errors.dateOfBirth)}
                helperText={errors.dateOfBirth?.message}
                label="Date of Birth"
                placeholder="dd/mm/yyyy"
                type="date"
                fullWidth
                {...register("dateOfBirth", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
            <div className="grid-cols-3 md:grid">
              <Controller
                control={control}
                name="nationalityId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="col-span-1"
                    error={Boolean(errors.nationalityId)}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    helperText={errors.nationalityId?.message}
                    inputLabelProps={{ required: true }}
                    label="Nationality"
                    options={countries}
                    placeholder="Select Country"
                    value={
                      value ? countries.find(item => item.uuid === value) : null
                    }
                    fullWidth
                    onChange={val => {
                      onChange(val?.uuid)
                    }}
                    required
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
            </div>

            <div className="flex flex-col sm:mt-2">
              <div className="mb-2 flex flex-row items-center">
                <Typography color="onSurface.500" variant="smallBody">
                  Where do you currently live?
                </Typography>
                <div className="grow pl-2">
                  <Divider weight="light" />
                </div>
              </div>
              <div className="grid-cols-6 md:grid">
                <div className="flex flex-col gap-3 md:col-span-5 md:flex-row">
                  <Controller
                    control={control}
                    name="countryId"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        error={Boolean(errors.countryId)}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        helperText={errors.countryId?.message}
                        label="country"
                        options={countries}
                        value={
                          value ? countries.find(c => c.uuid === value) : null
                        }
                        fullWidth
                        isClearable
                        onChange={val => {
                          if (val) {
                            fetchStates(val.uuid)
                          }
                          onChange(val ? val.uuid : "")
                        }}
                        required
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Required" },
                    }}
                  />

                  <Controller
                    control={control}
                    name="stateId"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        error={Boolean(errors.stateId)}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        helperText={errors.stateId?.message}
                        isLoading={states === undefined}
                        label="State/Region/Province"
                        options={states ?? []}
                        value={
                          value
                            ? states && states.find(c => c.uuid === value)
                            : null
                        }
                        fullWidth
                        isClearable
                        required
                        onChange={val => onChange(val ? val.uuid : "")}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Required" },
                    }}
                  />
                </div>
              </div>
            </div>
            <Button
              className="my-3 self-center md:self-start"
              endAdornment={<ArrowRight />}
              loading={isSubmitting}
              type="submit"
            >
              Continue
            </Button>
          </>
        )}
      </form>
    </div>
  )
}

export default PersonalDetails
