import {
  Button,
  IconButton,
  TextField,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { Search } from "iconoir-react"
import React, { useState } from "react"
import { useTheme } from "react-jss"
import { useSearchParams } from "react-router-dom"

type Props = {
  onSearch: (query: string) => void
}

const SearchField = ({ onSearch }: Props) => {
  const [searchParams] = useSearchParams()
  const [searchInput, setSearchInput] = useState(
    searchParams.get("search") || ""
  )
  const theme = useTheme<Theme>()
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <form
      className="flex gap-1 mb-3 md:gap-3"
      onSubmit={e => {
        e.preventDefault()
        onSearch(searchInput)
      }}
    >
      <TextField
        className="flex-grow"
        placeholder="Enter job title or school name"
        startIcon={<Search />}
        value={searchInput}
        fullWidth
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (e.target.value === "") {
            onSearch("")
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchInput(e.target.value)
        }}
      />
      {isDownMd ? (
        <IconButton size="md" type="submit" variant="filled">
          <Search />
        </IconButton>
      ) : (
        <Button type="submit">Find Jobs</Button>
      )}
    </form>
  )
}

export default SearchField
