import { WorkingDays as WorkingDaysType } from "api/resources/schools/types"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"
import { weekDays } from "utils/constants"

const useStyles = createUseStyles(theme => ({
  roundedDay: {
    borderRadius: "50px",
    width: "35px",
    padding: "0",
    display: "grid",
    placeItems: "center",
    height: "35px",
    color: theme.colors.onSurface[500],
    background: theme.colors.surface[200],
  },

  selectedDay: {
    color: "white",
    background: theme.colors.primary[500],
  },
}))

type Props = {
  workingDays?: WorkingDaysType[]
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>
function WorkingDays({ workingDays, ...props }: Props) {
  const classes = useStyles()

  return (
    <div className="flex items-center gap-1">
      {weekDays.map(item => (
        <button
          className={clsx(classes.roundedDay, {
            [classes.selectedDay]: (workingDays ?? []).includes(item.id),
          })}
          key={item.id}
          type="button"
          value={item.id}
          {...props}
        >
          {item.day.split("")[0]}
        </button>
      ))}
    </div>
  )
}

export default WorkingDays
