import { Button, Typography } from "@suraasa/placebo-ui-legacy"
import { JobOfferDetailsType } from "api/resources/jobs/types"
import CheckedCircle from "assets/icons/checkedCircle.svg"
import clsx from "clsx"
import { Download } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    border: `1px solid ${theme.colors.surface[200]}`,
    backgroundColor: theme.colors.surface[500],
    borderRadius: "4px",
  },
}))

const AcceptedOfferMessage = ({
  details,
}: {
  details: JobOfferDetailsType
}) => {
  const classes = useStyles()
  const auth = getAuthInfo()
  const user = auth && auth.user

  return (
    <div className={clsx("px-10 py-7", classes.root)}>
      <div className="mb-4 flex items-center ">
        <img src={CheckedCircle} alt="" height="70px" width="70px" />
        <Typography variant="title1">Woohoo!</Typography>
      </div>
      <Typography className="mb-4">
        Congratulations, {user?.firstName}.
        <br />
        You have successfully accepted the job offer for the role of&nbsp;
        <b>
          {details.job.position}, {details.job.subject.name}
        </b>
        &nbsp;at <b>{details.job.school.name}</b>.
      </Typography>
      <Typography className="mb-4">
        We recommend you to download the job offer and keep it handy.
      </Typography>
      <Typography className="mb-4">
        For any further communication, the school will directly contact you on
        your primary email address <b>{user?.email}</b>, or your registered
        phone number.
      </Typography>
      <Typography className="mb-4">
        If you wish to connect with the school, you can reach out to them
        at&nbsp;
        <b>{details.job.school.email}</b>.
        <br /> Please note that your "Apply for Jobs" feature is locked for the
        next 90 days.
      </Typography>
      <div className="flex flex-wrap items-center gap-2">
        <Button component={Link} to={routes.acceptedOffer}>
          Go to Dashboard
        </Button>
        <Button
          component="a"
          href={details.offerLetter}
          startAdornment={<Download />}
          // @ts-ignore placebo-issue
          target="_blank"
          variant="text"
        >
          Download Offer Letter
        </Button>
      </div>
    </div>
  )
}

export default AcceptedOfferMessage
