import { Avatar, Button, Divider, Typography } from "@suraasa/placebo-ui"
import { CoverStyle } from "api/resources/profile/types"
import clsx from "clsx"
import { GlobalContext } from "GlobalState"
import {
  Calendar,
  HeadsetHelp,
  Journal,
  LogOut,
  Medal,
  MediaVideoList,
  MicrophoneSpeaking,
  NavArrowDown,
  Settings,
  Shop,
  ShoppingBag,
  ViewGrid,
} from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { DEFAULT_PROFILE_COVER } from "types"
import { getAuthInfo } from "utils/auth"
import { buildUserName, getCoverImageURL, getPlatformURL } from "utils/helpers"

import AISparkle from "../../assets/icons/AISparkle"
import User from "../../assets/icons/User"

const duration = 225
const sideBarWidth = 320
const cubicBezier = "cubic-bezier(0, 0, 0.2, 1) 0ms"
const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    position: "absolute",
    left: -sideBarWidth,
    top: "0px",
    height: "100vh",
    width: sideBarWidth,
    zIndex: theme.zIndex.navbar,
    transition: `left ${duration}ms ${cubicBezier}`,
    willChange: "left",
    overflowY: "auto",
    overscrollBehavior: "none",
    "&.enter-active": { left: 0 },
    "&.enter-done": { left: 0 },
    "&.exit-active": { left: -sideBarWidth },
    "&.exit-done": { left: -sideBarWidth },
  },

  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  backdrop: {
    width: "100%",
    height: "100%",
    position: "fixed",
    left: 0,
    zIndex: theme.zIndex.navbar - 1,
    top: 0,
    backgroundColor: "transparent",
    backdropFilter: "blur(0px)",
    transition: `background-color backdrop-filter ${duration}ms ${cubicBezier}`,
    willChange: "background-color backdrop-filter",

    "&.enter-active": {
      backgroundColor: "rgba(0,0,0,0.25)",
      backdropFilter: "blur(2px)",
    },
    "&.enter-done": {
      backgroundColor: "rgba(0,0,0,0.25)",
      backdropFilter: "blur(2px)",
    },
    "&.exit-active": {
      backgroundColor: "transparent",
      backdropFilter: "blur(0px)",
    },
    "&.exit-done": {
      backgroundColor: "transparent",
      backdropFilter: "blur(0px)",
    },
  },

  arrowDown: {
    transform: "rotate(0deg)",
    transition: "transform 0.5s",
  },
  arrowDownActive: {
    transform: "rotate(180deg)",
  },

  termsConditionsButton: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  moreMenuItem: {
    display: "grid",
    gridTemplateRows: "0fr",
    transition: "all 0.5s",
  },
  moreMenuItemActive: {
    gridTemplateRows: "1fr",
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.onSurface[200],
  },
  isDisabled: {
    color: theme.colors.onSurface[500],
  },
  isCritical: {
    color: theme.colors.critical[500],
  },
}))

type Props = {
  open: boolean
  handleLogout: () => void
  handleClose: () => void
}

type MenuItem = {
  key?: string
  name: string
  icon: any
  endIcon?: JSX.Element
  bottomBorder?: boolean
  disabled?: boolean
} & ({ href: string } | { key: "more" | "sign_out" })

const menuItems: MenuItem[] = [
  {
    name: "Suraasa Profile",
    href: getPlatformURL("learn", "/home"),
    icon: User,
  },
  {
    name: "My Certificates",
    href: getPlatformURL("learn", "/certificates"),
    icon: Medal,
  },
  {
    name: "My Schedule",
    href: getPlatformURL("learn", "/my-schedule"),
    icon: Calendar,
    bottomBorder: true,
  },
  {
    name: "Orders and Payments",
    href: getPlatformURL("learn", "/orders"),
    icon: ShoppingBag,
  },
  {
    name: "Settings",
    href: getPlatformURL("learn", "/settings"),
    icon: Settings,
  },
  {
    name: "Help Centre",
    disabled: false,
    href: getPlatformURL("sso", "/help"),
    icon: HeadsetHelp,
  },
  {
    name: "Sign Out",
    key: "sign_out",
    icon: LogOut,
    bottomBorder: true,
  },
  {
    name: "More",
    key: "more",
    icon: ViewGrid,
  },
]

const moreMenuItems: MenuItem[] = [
  {
    name: "AI Tools",
    href: getPlatformURL("learn", "/ai-tools"),
    icon: AISparkle,
    disabled: false,
  },
  {
    name: "Store",
    href: getPlatformURL("suraasa", "/store"),
    icon: Shop,
  },
  {
    name: "Masterclasses",
    href: getPlatformURL("suraasa", "/masterclasses"),
    icon: MediaVideoList,
  },
  {
    name: "Blogs",
    href: getPlatformURL("suraasa", "/blog"),
    icon: Journal,
  },
  {
    name: "Podcasts",
    href: "https://podcasters.spotify.com/pod/show/suraasa",
    icon: MicrophoneSpeaking,
  },
]

const svgProps = {
  width: 20,
  height: 20,
}

const SidebarMenuItem = ({
  item,
  handleAction,
}: {
  item: MenuItem
  handleAction: () => void
}) => {
  const classes = useStyles()
  const display = (
    <div
      className={clsx("flex items-center gap-1 py-2", {
        [classes.bottomBorder]: item.bottomBorder,
        [classes.isDisabled]: item.disabled,
        [classes.isCritical]: ("key" in item && item.key) === "sign_out",
      })}
      style={{
        width: "100%",
        flexGrow: 1,
      }}
    >
      <item.icon {...svgProps} />
      <Typography variant="subtitle2">{item.name}</Typography>
    </div>
  )
  if ("href" in item && !item.disabled) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        style={{
          textDecoration: "none",
          color: "initial",
        }}
        to={item.href}
        onClick={handleAction}
      >
        {display}
      </Link>
    )
  }

  return (
    <button
      className="flex items-center justify-between"
      disabled={item.disabled}
      onClick={handleAction}
    >
      {display}
      {item.endIcon && item.endIcon}
    </button>
  )
}

const Sidebar = ({ open, handleLogout, handleClose }: Props) => {
  const classes = useStyles()
  const [showMore, setShowMore] = useState(false)
  const { profile } = useContext(GlobalContext)
  const coverStyle = profile.coverStyle as CoverStyle

  const authInfo = getAuthInfo()
  const userName = authInfo ? buildUserName(authInfo.user) : ""

  useEffect(() => {
    function handleEsc(e: KeyboardEvent) {
      if (e.key === "Escape") {
        handleClose()
      }
    }
    document.addEventListener("keydown", handleEsc)
    return () => {
      document.removeEventListener("keydown", handleEsc)
    }
  }, [handleClose])

  const handleAction = (item: MenuItem) => {
    if ("key" in item) {
      switch (item.key) {
        case "more":
          setShowMore(prevState => !prevState)
          return
        case "sign_out":
          handleLogout()
          return
        default:
          throw new Error("Action not handled in profile sidebar")
      }
    }
  }

  const getEndIcon = (item: MenuItem) => {
    if ("key" in item) {
      if (item.key === "more") {
        return (
          <div
            className={clsx(classes.arrowDown, {
              [classes.arrowDownActive]: showMore,
            })}
          >
            <NavArrowDown />
          </div>
        )
      }
    }
  }

  return (
    <>
      <CSSTransition
        in={open}
        timeout={duration}
        appear
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.root}>
          <div className={clsx(classes.content)}>
            {authInfo && (
              <div className="flex flex-col">
                <div className="relative">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    onClick={handleClose}
                    to={getPlatformURL("learn", "/profile")}
                    className="relative mb-[101px] block w-full px-0.5 pt-0.5"
                  >
                    <div
                      style={{
                        backgroundImage: `url(${getCoverImageURL(
                          coverStyle || DEFAULT_PROFILE_COVER,
                          true
                        )})`,
                      }}
                      className="relative h-[65px] w-full rounded bg-cover text-black"
                    />
                    <div className="absolute left-0 top-4.5 flex w-full flex-col items-center">
                      <Avatar
                        src={authInfo.user?.photo}
                        name={userName}
                        className="mb-1 size-9"
                      />
                      <Typography variant="title4">{userName}</Typography>
                      <Typography
                        variant="smallBody"
                        className="cursor-pointer text-onSurface-500"
                      >
                        View Profile
                      </Typography>
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col px-2">
                  <Divider className="bg-onSurface-200" weight="light" />
                  {menuItems.map((item, index) => (
                    <SidebarMenuItem
                      handleAction={() => {
                        if ("href" in item && !item.disabled) {
                          handleClose()
                        } else {
                          handleAction(item)
                        }
                      }}
                      item={{ ...item, endIcon: getEndIcon(item) }}
                      key={index}
                    />
                  ))}

                  <div
                    className={clsx(classes.moreMenuItem, {
                      [classes.moreMenuItemActive]: showMore,
                    })}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      {moreMenuItems.map((item, index) => (
                        <SidebarMenuItem
                          handleAction={() => {
                            if ("href" in item && !item.disabled) {
                              handleClose()
                            } else {
                              handleAction(item)
                            }
                          }}
                          item={item}
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col px-2 pb-1">
              <Link to={getPlatformURL("suraasa", "/privacy-policy")}>
                <Button
                  className={clsx(classes.termsConditionsButton)}
                  variant="text"
                >
                  <Typography
                    className="text-onSurface-400"
                    variant="smallBody"
                  >
                    Privacy Policy
                  </Typography>
                </Button>
              </Link>
              <Link to={getPlatformURL("suraasa", "/terms-of-use")}>
                <Button
                  className={classes.termsConditionsButton}
                  variant="text"
                >
                  <Typography
                    className="text-onSurface-400"
                    variant="smallBody"
                  >
                    Terms and Conditions
                  </Typography>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={open}
        timeout={duration}
        appear
        mountOnEnter
        unmountOnExit
      >
        <div
          className={classes.backdrop}
          role="presentation"
          onClick={handleClose}
        />
      </CSSTransition>
    </>
  )
}

export default Sidebar
