import { CoverStyle } from "api/resources/profile/types"

export type BaseComponentProps<T = Record<string, unknown>> = T & {
  className?: string
}

export type PropsWithClassName<T = Record<string, unknown>> = T & {
  className?: string
}

export const DEFAULT_PROFILE_COVER = CoverStyle.DUSK
