import {
  Button,
  Chip,
  Tag,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import {
  Job,
  JobInterestedUserStatus,
  JobInvitedUserStatus,
} from "api/resources/jobs/types"
import clsx from "clsx"
import AcceptApplicationDialog from "components/jobs/AcceptApplicationDialog"
import ListItem from "components/jobs/ListItem"
import RejectApplicationDialog from "components/jobs/RejectApplicationDialog"
import ProfileCompletion from "components/ProfileCompletion"
import TruncatedText from "components/shared/TruncatedText"
import SharedDialog from "components/SharedDialog"
import { format } from "date-fns"
import { GlobalContext } from "GlobalState"
import { Calendar, Check, MoneySquare, Pin } from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { generateProfileCompletionURL, isProfileComplete } from "utils/helpers"
import useEnrollments from "utils/hooks/useEnrollments"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { trackElementOnPH } from "utils/trackingService"

const useStyles = createUseStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    background: "white",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "4px",
  },
  description: {
    whiteSpace: "pre-line",
  },
}))

const JobOverviewCard = ({
  className,
  data,
}: {
  className?: string
  data: Job
}) => {
  const authInfo = getAuthInfo()
  const classes = useStyles()

  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false)
  const [profileCompletionDialog, toggleProfileCompletionDialog] =
    useToggle(false)
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)

  const { stepsOverview } = useContext(GlobalContext)

  const applyForJob = async () => {
    if (!authInfo) {
      const href = generateProfileCompletionURL({
        jobId: data.id.toString(),
      })
      if (href) {
        return (window.location.href = href)
      }
    } else if (!isProfileComplete(stepsOverview)) {
      toggleProfileCompletionDialog()
    } else {
      const res = await api.jobs.jobApplicant.create({
        urlParams: { jobId: data.id },
      })

      if (res.isSuccessful) {
        toast.success("Applied for Job Successfully")
        window.location.reload()
      } else toast.error(res.errors.message || "Something went wrong")
    }
  }

  const withdrawApplication = async () => {
    if (!data.jobApplicant) return
    setWithdrawLoading(true)
    const res = await api.jobs.jobApplicant.delete({
      urlParams: {
        jobApplicantId: data.jobApplicant.id,
      },
    })
    if (res.isSuccessful) {
      setWithdrawLoading(false)
      setWithdrawDialogOpen(false)
      toast.success("Application withdrawn")
      window.location.reload()
    } else {
      toast.error(res.errors.message)
    }
    setWithdrawLoading(false)
  }

  const { isPgCTLUser } = useEnrollments()

  const registerInterest = async (e?: React.MouseEvent) => {
    if (e) e.preventDefault()

    const res = await api.jobs.interestedUser.create({
      urlParams: {
        jobId: data.id,
      },
    })
    if (res.isSuccessful) {
      toast.success(
        "Great! Thank you for expressing interest in this job. Our placements team will be in touch with you"
      )
      window.location.reload()
    } else {
      toast.error("There was an issue while processing your request")
    }
  }

  const action = () => {
    if (data.jobApplicant) {
      return (
        <Button
          component={Link}
          size="sm"
          to={`${routes.jobTimeline
            .replace(":jobId", String(data.id))
            .replace(":jobApplicant", data.jobApplicant.id.toString())}`}
          variant="text"
        >
          View Application
        </Button>
      )
    }

    if (data.jobInterestedUser) {
      if (data.jobInterestedUser.status === JobInterestedUserStatus.PENDING) {
        return (
          <span>
            <Tooltip
              maxWidth="200px"
              title="Our placements team will be in touch with you"
            >
              <div>
                <Chip
                  color="success"
                  label="Applied"
                  size="sm"
                  startAdornment={<Check strokeWidth={3} />}
                />
              </div>
            </Tooltip>
          </span>
        )
      }
    }

    if (data.jobInvitedUser) {
      if (data.jobInvitedUser.status === JobInvitedUserStatus.PENDING) {
        return (
          <Button
            component={Link}
            size="sm"
            to={`${routes.schoolProfile.replace(
              ":slug",
              data.school.slug
            )}?jobSlug=${data.slug}`}
            variant="outlined"
          >
            View Invitation
          </Button>
        )
      }
    }

    if (data.eligibilityCriterion === "qualification") {
      return (
        <Tooltip
          disabled={isPgCTLUser}
          title="This job is only for Suraasa's PgCTL Learners"
        >
          <div>
            <Button
              disabled={!isPgCTLUser}
              size="sm"
              variant="text"
              component="a"
              href={`${routes.schoolProfile.replace(
                ":slug",
                data.school.slug
              )}?jobSlug=${data.slug}&applyingOnJobId=${data.id}&ri=true`}
            >
              Register Interest
            </Button>
          </div>
        </Tooltip>
      )
    }

    return (
      <Button
        component="a"
        size="sm"
        href={`${routes.schoolProfile.replace(
          ":slug",
          data.school.slug
        )}?jobSlug=${data.slug}&applyingOnJobId=${data.id}`}
        variant="text"
        {...trackElementOnPH({
          job_id: data.id,
          job_slug: data.slug,
        })}
      >
        Apply
      </Button>
    )
  }

  return (
    <>
      {withdrawDialogOpen && (
        <SharedDialog
          actionLabel="Withdraw"
          handleClose={() => setWithdrawDialogOpen(false)}
          loading={withdrawLoading}
          open={withdrawDialogOpen}
          title="Withdraw Application"
          isDestructive
          onConfirm={withdrawApplication}
        >
          <Typography variant="body">
            Are you sure you want to withdraw your application from{" "}
            <b>{data.school.name}’s</b> job opening for&nbsp;
            <b>
              {data.position}, {data.subject.name}
            </b>
            ?
          </Typography>
        </SharedDialog>
      )}
      {stepsOverview && (
        <ProfileCompletion
          jobId={data.id}
          open={profileCompletionDialog}
          toggle={toggleProfileCompletionDialog}
        />
      )}
      {rejectDialogOpen && data.jobApplicant && (
        <RejectApplicationDialog
          afterSubmit={() => {
            /**
             * We're directly updating props here because its convenient.
             * This kind of code usually doesn't work but here it's is only working because inside this component, we're calling `afterSubmit` first, then `handleClose`.
             * We're updating the state in handleClose which is causing a re-render.
             *
             * This code would not work if we didn't have handleClose updating the state. It wouldn't trigger a re-render.
             * Updaing parent state is not that important because it will re-fetch the data when we go back anyway.
             */
            data.jobApplicant = null
          }}
          handleClose={() => {
            setRejectDialogOpen(false)
          }}
          jobApplicantId={data.jobApplicant.id}
          jobPosition={data.position}
          mode="invitation"
          open={rejectDialogOpen}
          schoolName={data.school.name}
        />
      )}

      {acceptDialogOpen && data.jobApplicant && (
        <AcceptApplicationDialog
          afterSubmit={() => {
            setAcceptDialogOpen(false)
            toast.success("Invite accepted", {
              duration: 7000,
              body: "Please check Active Applications Tab",
            })
          }}
          handleClose={() => {
            setAcceptDialogOpen(false)
          }}
          jobApplicantId={data.jobApplicant.id}
          jobPosition={data.position}
          open={acceptDialogOpen}
          schoolName={data.school.name}
          subjectName={data.subject.name}
        />
      )}

      <div className={clsx(classes.root, className)}>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <div>
            <span className="flex items-center gap-1">
              <Typography variant="strong">{data.position}</Typography>
              <div className="flex items-center gap-1">
                {data.school.curriculumBoard.map(item => (
                  <Chip
                    key={item.uuid}
                    label={item.name}
                    size="sm"
                    variant="outlined"
                  />
                ))}
              </div>
            </span>
            <Typography color="onSurface.500">{data.school.name}</Typography>
          </div>
          {action()}
        </div>
        <Tag className="my-3" label={data.subject.name} />
        <div className="mt-3 flex flex-col gap-3 md:flex-row md:items-center md:justify-between md:gap-0">
          <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-6">
            <span>
              <ListItem icon={<Pin />}>Location</ListItem>
              <Typography className="ml-0.5" variant="strong">
                {data.school.state?.name && data.school.country?.name
                  ? `${(data.school.state.name, data.school.country.name)}`
                  : "-"}
              </Typography>
            </span>
            <span>
              <ListItem icon={<Calendar />}>Posted on</ListItem>
              <Typography className="ml-0.5" variant="strong">
                {data.datePublished
                  ? format(new Date(data.datePublished), "d MMM yyyy")
                  : "-"}
              </Typography>
            </span>
            <span>
              <ListItem icon={<MoneySquare />}>Salary Offered</ListItem>
              <div className="flex items-center">
                {data.salaryNegotiable ? (
                  <Typography variant="strong">Salary Negotiable</Typography>
                ) : (
                  data.currency &&
                  data.salaryOffered && (
                    <>
                      <Typography className="ml-0.5" variant="strong">
                        {data.currency.code}{" "}
                        {data.salaryOffered.toLocaleString()}
                        {data.maximumSalaryOffered ? (
                          <> - {data.maximumSalaryOffered.toLocaleString()}</>
                        ) : null}
                      </Typography>
                      <Typography color="onSurface.500" variant="smallBody">
                        {" "}
                        /year
                      </Typography>
                    </>
                  )
                )}
              </div>
            </span>
          </div>

          {/* <span className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
         <Typography color="success.500">Applied</Typography>
          </span> */}
        </div>
        {data.description && (
          <TruncatedText
            className={`${classes.description} mt-2.5`}
            color="onSurface.600"
            maxLength={400}
            variant="body"
          >
            {data.description}
          </TruncatedText>
        )}
      </div>
    </>
  )
}

export default JobOverviewCard
